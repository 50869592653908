import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import instance from '../../services/baseService';


const SheetSubmitSkipModal = ({ showSkipModal, handleSkipClose,  handleSkipSubmit}) => {
    const [selectedOption, setSelectedOption] = useState('');
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [validated, setValidated] = useState(false);

    const handleSubmit = async () => {
        try {
            if (!selectedOption) {
                setValidated(true); // Show validation error if no option is selected
            } else {
                await handleSkipSubmit(selectedOption); // Close the modal after submission
                handleSkipClose();
            }
        } catch (error) {
            console.error("Error submitting data:", error);
            // Handle error appropriately
        }
    };

    const fetchAccessLevels = async () => {
        setLoading(true);
        try {
            const response = await instance.get(`/api/v1/access/level/1`);
            setOptions(response?.data?.data?.roles); 
        } catch (error) {
            console.error('Error fetching options:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        if (showSkipModal) {
            fetchAccessLevels();
        }
    }, [showSkipModal]);

    return (
        <Modal show={showSkipModal} onHide={handleSkipClose} enforceFocus={false} backdrop="static" id="skip-modal">
            <Modal.Header closeButton>
                <Modal.Title>Skip Submission</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                <p>Loading options...</p>
                ) : (
                <Form noValidate validated={validated}>
                    <Form.Group controlId="formSelect">
                        <Form.Label>Select user category</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedOption}
                            onChange={(e) => setSelectedOption(e.target.value)} // Handle change
                            isInvalid={validated && !selectedOption}
                        >
                            <option value="">Select...</option> {/* Default empty option */}
                            {options.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                            ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                            Please select an option.
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-primary" onClick={handleSkipClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SheetSubmitSkipModal;