import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import instance from '../services/baseService'
import { ROUTES } from '../constants/clients'
import { errorMsgCreator } from '../utils'

export const getClients = createAsyncThunk('clients/getClients', async (page_number, thunkAPI) => {
  if (!page_number) page_number = 1
  try{
  const response = await instance.get(ROUTES.LIST_CLIENT(page_number))
  return response.data
  } catch(err) {
    return thunkAPI.rejectWithValue(err?.response?.data) 
  }
})

export const getPendingClients = createAsyncThunk('clients/getPendingClients', async (page_number, thunkAPI) => {
  if (!page_number) page_number = 1
  const per_page = 100
  try{
  const response = await instance.get(ROUTES.LIST_PENDING_CLIENT(page_number, per_page))
  return response.data
  } catch(err) {
    return thunkAPI.rejectWithValue(err?.response?.data) 
  }
})

export const getSingleClient = createAsyncThunk('clients/getSingleClient', async (id, thunkAPI) => {
  try{
  const response = await instance.get(ROUTES.GET_SINGLE_CLIENT(id))
  return response.data
} catch(err) {
  return thunkAPI.rejectWithValue(err?.response?.data) 
}
})

export const addClients = createAsyncThunk('clients/addClient', async ({data, page_number}, thunkAPI) => {
  try{
  const response = await instance.post(ROUTES.CREATE_CLIENT, data)
  if(response.data) {
    thunkAPI.dispatch(getClients(page_number))
  }
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data)
  }
})

export const updateClient = createAsyncThunk('clients/updateClient', async ({data, page_number, client_id}, thunkAPI) => {
  const response = await instance.post(ROUTES.UPDATE_CLIENT(client_id), data)
  try {
    if (response.data) {
      thunkAPI.dispatch(getClients(page_number))
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data)
  }
})

export const updatePendingClientStatus = createAsyncThunk('clients/updatePendingClientStatus', async ({active, page_number, client_id}, thunkAPI) => {
  const response = await instance.post(ROUTES.UPDATE_PENDING_CLIENT_STATUS(client_id), {active})
  try {
    if (response.data) {
      thunkAPI.dispatch(getPendingClients(page_number))
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data)
  }
})

export const deleteClient = createAsyncThunk('clients/delete', async ({data, page_number}, thunkAPI) => {
  try {
    const response = await instance.post(ROUTES.DELETE_CLIENT(data))
    if (response.data) {
      thunkAPI.dispatch(getClients(page_number))
    }
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data)
  }
})

export const clientSlice = createSlice({
  name: 'clients',
  initialState: {
    data: [],
    pendingData: [],
    client: {},
    loading: false,
    error: null,
    success: false,
    successMsg: null, 
    total_count: 0,
    total_pending_count: 0
  },
  reducers: {
    resetStore(state){
      state.error = null
      state.success = false
      state.successMsg = null
    }

  },
  extraReducers: (builder) => {
    builder.addCase(getClients.pending, (state, action) => {
        state.loading = true
    })
    builder.addCase(getClients.fulfilled, (state, action) => {
    
        state.data = action.payload.data?.lists
        state.loading = false
        state.total_count = action.payload.data?.total_count
    })
    builder.addCase(getClients.rejected, (state, action) => {
        state.loading = false
        state.error = 'Unable to fetch client list'
    })
    builder.addCase(getPendingClients.pending, (state, action) => {
        state.loading = true
    })
    builder.addCase(getPendingClients.fulfilled, (state, action) => {
    
        state.pendingData = action.payload.data?.lists
        state.loading = false
        state.total_pending_count = action.payload.data?.total_count
    })
    builder.addCase(getPendingClients.rejected, (state, action) => {
        state.loading = false
        state.error = 'Unable to fetch client list'
    })
    builder.addCase(getSingleClient.pending, (state, action) => {
        state.loading = true
    })
    builder.addCase(getSingleClient.fulfilled, (state, action) => {
        state.client = action.payload.data
        state.loading = false
    })
    builder.addCase(getSingleClient.rejected, (state, action) => {
        state.loading = false
        state.error = 'Unable to fetch client'
    })
    builder.addCase(addClients.pending, (state, action) => {
        state.loading = true
    })
    builder.addCase(addClients.fulfilled, (state, action) => {
        state.loading = false
        state.successMsg = 'Successfully added Client'
        state.success = true
    })
    builder.addCase(addClients.rejected, (state, action) => {
        state.loading = false
        let data  = action.payload.data;
      
        let error = `Unable to add client. `
        error = errorMsgCreator(error, data)
        state.error = error
         
    })
    builder.addCase(updateClient.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(updateClient.fulfilled, (state, action) => {
        state.loading = false
        state.successMsg = 'Successfully updated Client'
        state.success = true
        state.client = {}

    })
    builder.addCase(updateClient.rejected, (state, action) => {
        state.loading = false
        let data  = action.payload.data;
        let error = 'Unable to update client. '
        state.client = {}
        state.error = errorMsgCreator(error, data)
        
    })

    builder.addCase(updatePendingClientStatus.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(updatePendingClientStatus.fulfilled, (state, action) => {
        state.loading = false
        state.successMsg = 'Successfully updated pending client status'
        state.success = true

    })
    builder.addCase(updatePendingClientStatus.rejected, (state, action) => {
        state.loading = false
        let data  = action.payload.data;
        let error = 'Unable to update client. '
        state.error = errorMsgCreator(error, data)
        
    })
  builder.addCase(deleteClient.pending, (state, action) => {
      state.loading = true
  })
  builder.addCase(deleteClient.fulfilled, (state, action) => {
      state.loading = false
      state.successMsg = 'Successfully deleted client'
      state.success = true
  })
  builder.addCase(deleteClient.rejected, (state, action) => {
      state.loading = false
      let data  = action.payload.data;
      state.error = errorMsgCreator('Unable to delete client. ', data) 
  })
  },
})


export const { resetStore } = clientSlice.actions
export default clientSlice.reducer