import {string,object} from 'yup';


export const LITIGATION_CREATION_FIELDS = {
  
  particular: {
      type: "text",
      name: "particular",
      label: "Particular",
    },
    period: {
      type: "text",
      name: "period",
      label: "Period",
    },
    current_status: {
      type: "text",
      name: "current_status",
      label: "Current Status",
    },
    final_status: {
      type: "text",
      name: "final_status",
      label: "Final Status",
    },
  };


export const LITIGATION_CREATION_SCHEMA = object().shape({
  lit_id: string().when([], {
    is: (val) => !!val, 
    then: string().required("Litigation ID is required for updates"),
  }),
  particular: string().required("Particulars are required"),
  period: string().required("Period is required"),
  current_status: string().required("Current Status is required"),
  final_status: string().required("Final Status is required"),
  });


