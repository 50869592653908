import { Fragment, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./components/PrivateRouter";
import Dashboard from "./components/Dashboard";
import Statement3 from "./components/Statement3";
import Folder from "./components/Folder";
import Login from "./components/Login";
import FileUpload from "./components/FileUpload";
import Instruction from "./components/files/Instruction";
import UserModule from "./components/User";
import UserList from "./components/user/UserList";
import AddUser from "./components/user/AddUser";
import EditUser from "./components/user/EditUser";
import UnAuthorized from "./components/common/UnAuthorized";
import AssignClient from "./components/user/AssignClient";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "./App.css";
import Gstr1 from "./components/Gstr1";
import { Toaster } from "react-hot-toast";
import Refunds from "./components/refund/Refunds";
import LitigationComponent from "./components/litigation/Litigation";
import ExportImportFacilitation from "./components/ExportImportFacilitation/ExportImportFacilitation";


import Clients from "./components/Clients";
import PendingClients from "./components/PendingClients";
import TaxForms from "./components/TaxForms";
import Forex from "./components/Forex/Forex";
import ManageProfile from "./components/ManageProfile";
import Gstr3b from "./components/gstr3b/Gstr3b";
import Gstr9 from "./components/gstr9/Gstr9";
import Gstr9c from "./components/gstr9c/Gstr9c";
import { generateToken, messaging } from "./notification/firebaseConfig";
import { onMessage } from "firebase/messaging";
import { useDispatch } from "react-redux";
import { setFcmDeviceToken, setFcmNotification } from "./slices/notificationReducer";

function App() {

  const dispatch = useDispatch();
  const [foregroundMessageReceived, setForegroundMessageReceived] = useState(false);

  useEffect(() => {
    const setToken = async () => {
      const deviceToken = await generateToken();
      if(deviceToken)
        dispatch(setFcmDeviceToken(deviceToken));
      /*onMessage(messaging, (payload) => {
        console.log('Message received. ', payload.data);
        setForegroundMessageReceived(true); 
        dispatch(setFcmNotification(payload.data));
      });*/
    }
    setToken();
  }, [dispatch]);

  useEffect(() => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', (event) => {
          if (!foregroundMessageReceived && event.data?.data?.fcm_app === 'admin') {
            console.log('Received message from service worker:', event.data?.data);
            dispatch(setFcmNotification(event.data?.data));
            
          }
        });
    }
  }, [foregroundMessageReceived, dispatch]);
  return (
    <Fragment>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Router>
          <Toaster />
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route element={<Dashboard />} path="/" />
              <Route element={<Statement3 />} path="/statement3" />
              <Route element={<Forex />} path="/forex" />
              <Route element={<Clients />} path="/clients" />
              <Route element={<PendingClients />} path="/pending-clients" />
              <Route
                element={<TaxForms />}
                path="/clients/tax-forms/:client_id"
              />
              <Route element={<Gstr1 />} path="/gstr-1/:client_id" />
              <Route element={<Gstr3b />} path="/gstr3b/:client_id" />
              <Route element={<Gstr9 />} path="/gstr9/:client_id" />
              <Route element={<Gstr9c />} path="/gstr9c/:client_id" />
              <Route element={<Refunds />} path="/refunds/:client_id" />
              <Route element={<LitigationComponent />} path="/litigation/:client_id" />
              <Route element={<ExportImportFacilitation />} path="/Export-Import-Facilitation" />
              

              <Route element={<Folder />} path="/periods/:client_id" />
              
              <Route element={<FileUpload />} path="/files/:folder_id" />
              <Route element={<Instruction />} path="/instructions" />
              <Route element={<UserModule />} path="/user-management">
                <Route element={<UserList />} index />
                <Route element={<AddUser />} path="add-user" />
                <Route element={<EditUser />} path="edit-user/:user_id" />
                <Route
                  element={<AssignClient />}
                  path="assign-client/:user_id"
                />
              </Route>
              <Route element={<UnAuthorized />} path="/authorization-error" />
              <Route element={<ManageProfile />} path="/manage-profile" />
            </Route>
            <Route element={<Login />} path="/login" />
          </Routes>
        </Router>
      </GoogleOAuthProvider>
    </Fragment>
  );
}

export default App;
