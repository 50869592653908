import React, { useState } from "react";
import "../XlsxTable.css";
import moment from 'moment';
import { RxCross2 } from "react-icons/rx";
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaCircleDot } from "react-icons/fa6";

function NoteModal({ noteArr, note, onSave, onClose, status, setStatus }) {
  const [currentNote, setCurrentNote] = useState(note);
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="fixed h-[19rem] top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white border border-gray-700  rounded-md z-[1001] shadow-lg">
      <div className="bg-[] w-fit p-1 rounded-full absolute bg-white shadow-md -right-3 -top-3 border cursor-pointer">
        <RxCross2 onClick={onClose} className="text-black text-lg" />
      </div>
      <div className="grid grid-cols-2 divide-x border-b rounded-md">
        <span
          onClick={() => setActiveTab(0)}
          className={`${activeTab === 0 ? 'bg-[#1e4466] text-white' : ''} cursor-pointer py-2 px-4`}>
          Recent Comment
        </span>
        <span
          onClick={() => setActiveTab(1)}
          className={`${activeTab === 1 ? 'bg-[#1e4466] text-white' : ''} cursor-pointer py-2 px-4`}>
          Add Comment
        </span>
      </div>
      <div className="p-3 flex flex-col">
        {activeTab === 0 ? (
          <div className="divide-y h-[15rem] overflow-y-scroll">
            {noteArr.length == 0 ? (
              <p className="text-center">No recent comments</p>
            ) : (
              noteArr.map((note) => (
                <div className="flex flex-col py-2 px-3 w-full">
                  <div class="flex items-center justify-between">
                    <p className="font-medium text-lg m-0">{note.comment}</p>
                    {note.status && <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<Tooltip id={`button-tooltip-${note.id}`}>
                            {note.status === 1 && 'Approved'}
                            {note.status === 2 && 'Rejected'}
                            {note.status === 4 && 'Resolved'}
                          </Tooltip>}
                          container={document.body}
                      >
                        <span id={`button-tooltip-${note.id}`} className="ml-auto"><FaCircleDot className={`${note.status === 1 && 'text-success'} ${note.status === 2 && 'text-danger'} ${note.status === 4 && 'text-warning'}`}/></span>
                        
                      </OverlayTrigger>}
                  </div>
                  <span className="text-xs text-slate-500">
                    {moment(note.created_time_formatted).format('DD-MM-YYYY')}
                  </span>
                  <span className="text-xs text-slate-500">
                    Added by: {note.created_user_profile?.name}
                  </span>
                </div>
              ))
            )}
          </div>
        ) : (
          <div>
            <label htmlFor="comment" className="mr-auto font-medium">
              Comment:
            </label>
            
               <Form.Control
                as="textarea"
                id="comment"
                className="border p-2 resize-none rounded-md"
                value={currentNote}
                onChange={(e) => setCurrentNote(e.target.value)}
                placeholder="Add your note here..."
              />
            <div className="space-x-2 flex justify-end">
              <button
                onClick={() => onSave(currentNote,status)}
                className="border-2 border-[#1e4466] bg-[#1e4466] text-white px-4 py-px rounded-[3px] justify-self-center">
                Resolve & Submit
              </button>
              <button
                onClick={onClose}
                className="border-2 border-[#1e4466] px-4 py-px rounded-[3px] justify-self-center">
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NoteModal;
