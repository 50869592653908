import { Placeholder } from "react-bootstrap";
import { string, object, number } from "yup";


export const ROUTES = {
    CREATE_HSNSAC:"api/v1/hsnrepo/add",
    UPDATE_HSNSAC: "api/v1/hsnrepo/edit",
    LIST_HSNSAC: "api/v1/hsnrepo/search",
    PENDING_LIST:"api/v1/hsnrepo/pending",
    BULK_ACTION:"api/v1/hsnrepo/approve",
    QUERY_LIST:"api/v1/hsnrepo/query/list/1",
    APPROVAL_LEVEL_LIST:"api/v1/hsnrepo/view/level",
    APPROVAL_LEVEL_UPDATE:"api/v1/hsnrepo/insert/level",
    QUERY_ADMIN_VIEW:"/api/v1/hsnrepo/query/admin/view/",
    CREATE_ADMIN_COMMENT:"api/v1/hsnrepo/query/admin/reply/",
    COMMENT_LIST:"api/v1/hsnrepo/query/comments/admin/",
    QUERY_APPROVE:"api/v1/hsnrepo/query/admin/approve/",
    BULK_UPLOAD:"api/v1/hsnrepo/upload"


}



export const HSN_SAC_CREATION_FIELDS = {
    hsn_number: {
    type: "text",
    name: "hsn_number",
    label: "HSN",
    placeholder:"Enter the number"
    
  },
  description: {
    type: "text",
    name: "description",
    label: "Description",
    placeholder:"Enter the description"
  },
  types: {
    type: "text",
    name: "types",
    label: "Type",
  },
  rate: {
    type: "text",
    name: "rate",
    label: "Rate",
    placeholder:"Enter the rate"
  },
};

export const HSN_SAC_CREATION_SCHEMA = object().shape({
  hsn_number: string()
    .required("Required")
    .matches(/^\d+$/, "Only accepts numbers")
    .max(20, "This field must not exceed 20 characters"),
  description: string().required("Description is required").max(255, "This field must not exceed 255 characters"),
  types: string()
    .required("Type is required") 
    .matches(/^(HSN|SAC)$/, "Type must be a specific predefined value"), 
    rate: number()
    .required("Rate is required") 
    .positive("Rate must be a positive number")
    .test(
      "is-decimal",
      "Rate can have up to 2 decimal places",
      (value) => value === undefined || /^\d+(\.\d{1,2})?$/.test(value.toString())
    )
    .typeError("Rate must be a number"),

});


// ReactQuill modules
export const modules = {
  toolbar: [
    [{ 'bold': true }, { 'italic': true }, { 'underline': true }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'align': [] }],
    ['link'],
    ['clean']
  ]
};