import { Fragment } from "react"
import Sidebar from "./common/Sidebar";
import Header from "./common/Header";
import Footer from "./common/Footer";
export default function Base(props) {

    const {children, sticky_footer = true, header_title = '', hideDatepicker=true, headerType='', showSearchIcon} = props;
    return (
        <Fragment>
        
        <div className="wrapper">
            <Sidebar/>
            <Header  title={header_title} hideDatepicker={hideDatepicker} headerType={headerType} showSearchIcon={showSearchIcon}/>
            <div className="content-page">
                <div className="container-fluid mb-14">
                    {children}
                </div>
                
            </div>
            <Footer sticky={sticky_footer}/>
        </div>
        </Fragment>
        
    )
}