import { Table, Button } from "react-bootstrap";
export default function PendingListView(props){

    const { 
        headers,
        data,
        handlePendingClientApprove,
        isAdmin
     } = props;
   

    return (
        
            <>    
                      {Array.isArray(data) && data.length > 0 && ( <Table
                            striped 
                            bordered 
                            hover
                            responsive
                           
                        >
                            <thead>
                                <tr>
                                    {headers.map(header => {
                                        return <th scope="col" key={header.id}>{header.name}</th>
                                    })}
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>                                
                                   {Array.isArray(data) && data.map((ind_data) => {
                                    return (<tr key={ind_data.id}> 
                                        {Array.isArray(headers) && headers.map(header => {
                                        return ( <td key={header.id}>
                                            {`${(!!header.modifier ? header.modifier(ind_data[header.id]) : ind_data[header.id] )} ${(!!header.suffix ? header.suffix : '' )}`}
                                            </td>
                                            )

                                     })}
                                     <td>
                                        {isAdmin && 
                                            <>
                                            <Button
                                            variant="primary"
                                            size="sm"
                                            className="py-0 px-1 !text-[12px]"
                                            onClick={() => handlePendingClientApprove(ind_data.id, true)}
                                            >
                                              Approve
                                            </Button>
                                            <span className="mx-1">|</span>
                                            <Button
                                            variant="outline-primary"
                                            size="sm"
                                            className="py-0 px-1 !text-[12px]"
                                            onClick={() => handlePendingClientApprove(ind_data.id, false)}
                                            >  
                                             Reject
                                            </Button>
                                           </>
                                        }
                                    
                                    </td>
                                     </tr>)
                                   })}
                                   
                               
                             </tbody>    
                            </Table>)}
                            {!data?.length && (<div> No Pending Clients Found </div>) }
                            </> 
                            
    )
}