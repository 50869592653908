import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Base from "../Base";
import { Row, Col, Button, Form, Tab, Tabs, Table } from "react-bootstrap";
import { FaCogs, FaCopy } from "react-icons/fa";
import BulkImportModal from "./BulkImportModal";
import AddEditHsnSacModal from "./AddEditHsnSacModal";
import RoleSelectionPopup from "./hierarchy";
import DescriptionCell from "./descriptionCell";
import Spinner from "react-bootstrap/Spinner";
import {
  getHsnSac,
  resetHsnSacState,
  HsnSacPendingList,
  BulkAction,
  QueryList,
} from "../../slices/hsnSacReducer";
import { useSelector, useDispatch } from "react-redux";
import PaginationComponent from "../common/Pagination";
import Alert from "react-bootstrap/Alert";
const HsnSacComponent = () => {
  const [activeTab, setActiveTab] = useState("HSN");
  const [showBulkImport, setShowBulkImport] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showHierarchy, setShowHierarchy] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [current_page, setCurrentPage] = useState(1);
  const [query, setQuery] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    hsnSacData,
    pendingHsnSacData,
    queryList,
    bulkActionSuccess,
    total_count,
    loading,
    error,
    success,
    successMsg,
  } = useSelector((state) => state.hsnSac);
  const { role_id: roleId } = useSelector((state) => state.common);
  const per_page = 25;

  const handleCheckboxChange = (id) => {
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.includes(id)
        ? prevSelectedRows.filter((rowId) => rowId !== id)
        : [...prevSelectedRows, id]
    );
  };

  const handleSearchChange = (e) => {
    setQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const params = { page: current_page, perpage: per_page };
        if (query.trim() && activeTab !== "QUERIES") {
          params.query = query.trim();
        }

        if (selectedStatus) {
          params.status = [selectedStatus === "Completed" ? 1 : 0];
        }

        switch (activeTab) {
          case "HSN":
          case "SAC":
            await dispatch(getHsnSac({ ...params, types: activeTab })).unwrap();
            break;
          case "HSN_PENDING":
            await dispatch(
              HsnSacPendingList({ ...params, types: "HSN" })
            ).unwrap();
            break;
          case "SAC_PENDING":
            await dispatch(
              HsnSacPendingList({ ...params, types: "SAC" })
            ).unwrap();
            break;
          case "QUERIES":
            await dispatch(QueryList(params)).unwrap();
            break;
          default:
            console.warn("Unhandled tab type:", activeTab);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [dispatch, activeTab, selectedStatus, query, current_page]);

  const handlePagination = async (page) => {
    try {
      setCurrentPage(page);
      const params = { page: page, perpage: per_page };
      if (query.trim() && activeTab !== "QUERIES") {
        params.query = query.trim();
      }

      if (selectedStatus) {
        params.status = [selectedStatus === "Completed" ? 1 : 0];
      }

      switch (activeTab) {
        case "HSN":
        case "SAC":
          await dispatch(getHsnSac({ ...params, types: activeTab })).unwrap();
          break;
        case "HSN_PENDING":
          await dispatch(
            HsnSacPendingList({ ...params, types: "HSN" })
          ).unwrap();
          break;
        case "SAC_PENDING":
          await dispatch(
            HsnSacPendingList({ ...params, types: "SAC" })
          ).unwrap();
          break;
        case "QUERIES":
          await dispatch(QueryList({ ...params, types: "QUERIES" })).unwrap();
          break;
        default:
          console.warn("Unhandled tab type for pagination:", activeTab);
      }
    } catch (error) {
      console.error("Error during pagination:", error);
    }
  };

  const handleEditClick = (item) => {
    const updateFormData = {
      id: item?.id,
      hsn_number: item?.hsn_number,
      description: item?.description,
      types: item?.type,
      rate: item?.rate,
    };
    setEditData(updateFormData);
    setShowAddEditModal(true);
    setIsUpdate(true);
    dispatch(resetHsnSacState());
  };

  const handleAddClick = () => {
    setEditData(null);
    setShowAddEditModal(true);
    setIsUpdate(false);
    dispatch(resetHsnSacState());
  };

  const handleApproveReject = async (ids, approve) => {
    try {
      await dispatch(BulkAction({ approval_ids: ids, approve })).unwrap();
      setSelectedRows([]);

      switch (activeTab) {
        case "HSN_PENDING":
          await dispatch(
            HsnSacPendingList({ page: 1, perpage: per_page, types: "HSN" })
          ).unwrap();
          break;
        case "SAC_PENDING":
          await dispatch(
            HsnSacPendingList({ page: 1, perpage: per_page, types: "SAC" })
          ).unwrap();
          break;
        default:
          console.warn("Unhandled tab type:", activeTab);
      }
    } catch (error) {
      console.error("Error handling approve/reject action:", error);
    }
  };

  const renderTableContent = (type) => {
    try {
      if (hsnSacData.length == 0) {
        console.log("hsnSacData", hsnSacData);
        return (
          <tr>
            <td colSpan="100%" style={{ textAlign: "center", padding: "20px" }}>
              No data available
            </td>
          </tr>
        );
      }
      return hsnSacData
        .filter((item) => item.type === type)
        .map((item, index) => (
          <tr
            key={index}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <td className="position-relative">
              {item.hsn_number}
              {hoveredRow === index && (
                <Button
                  variant="Info"
                  size="sm"
                  className="copy-button position-absolute"
                  onClick={() => handleCopy(item.hsn_number, index)}
                >
                  <FaCopy style={{ fontSize: "10px" }} />
                </Button>
              )}
              {/* "Copied" message overlay */}
              {copiedIndex === index && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    fontSize: "9px",
                    fontWeight: "bold",
                    color: "green",
                    opacity: "100%",
                    pointerEvents: "none",
                    animation: "fadeInOut 3s",
                  }}
                >
                  Copied
                </div>
              )}
            </td>
            <DescriptionCell
              description={item.description}
              progressbar={false}
            />
            <td>{item.rate}%</td>
            <td>{item.updated_time_formatted.split("T")[0]}</td>
            <td style={{ display: "Flex", justifyContent: "space-evenly" }}>
              <Button
                className="me-2"
                variant="success"
                onClick={() => handleEditClick(item)}
              >
                Update
              </Button>
              {/* <Button
                className="me-2"
                variant="danger"
                onClick={() => handleEditClick(item)}
              >
                Delete
              </Button> */}
            </td>
          </tr>
        ));
    } catch (error) {
      console.error("Error handling renderTableContent action:", error);
    }
  };

  const renderPendingTableContent = (type) => {
    try {
      if (pendingHsnSacData.length === 0) {
        return (
          <tr>
            <td colSpan="100%" style={{ textAlign: "center", padding: "20px" }}>
              No pending data available
            </td>
          </tr>
        );
      }
      return pendingHsnSacData
        .filter((item) => item.type === type)
        .map((item, index) => (
          <tr
            key={index}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <td>
              <Form.Check
                type="checkbox"
                onChange={() => handleCheckboxChange(item.id)}
                checked={selectedRows.includes(item.id)}
                className="custom-checkbox"
                disabled={item.action_required === 0}
              />
            </td>
            <td className="position-relative">
              {item.hsn_number}
              {hoveredRow === index && (
                <Button
                  variant="Info"
                  size="sm"
                  className="copy-button position-absolute"
                  onClick={() => handleCopy(item.hsn_number, index)}
                >
                  <FaCopy style={{ fontSize: "10px" }} />
                </Button>
              )}
              {/* "Copied" message overlay */}
              {copiedIndex === index && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.8)",
                    fontSize: "9px",
                    fontWeight: "bold",
                    color: "green",
                    opacity: "100%",
                    pointerEvents: "none",
                    animation: "fadeInOut 3s",
                  }}
                >
                  Copied
                </div>
              )}
            </td>
            <DescriptionCell
              description={item.description}
              levels={item.levels}
              progressbar={true}
            />
            <td>{item.rate}%</td>
            <td>{item.action}</td>
            <td>{item.updated_time_formatted.split("T")[0]}</td>
            <td>
              {item.action_required === 1 ? (
                <>
                  <Button
                    className="me-2"
                    variant="success"
                    onClick={() => handleApproveReject([item?.id], 1)}
                  >
                    Approve
                  </Button>
                  <Button
                    variant="danger"
                    onClick={() => handleApproveReject([item?.id], 2)}
                  >
                    Reject
                  </Button>
                </>
              ) : (
                <Button variant="secondary" className="rounded-pill" disabled>
                  Pending
                </Button>
              )}
            </td>
          </tr>
        ));
    } catch (error) {
      console.error("Error handling renderPendingTableContent action:", error);
    }
  };

  const handleQueryClick = (query) => {
    navigate(`/query-details/${query.client_details.id}/${query.id}`);
  };

  const handleMouseEnter = (index) => {
    setHoveredRow(index);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };

  const handleCopy = (text, index) => {
    navigator.clipboard.writeText(text);
    setCopiedIndex(index);
    setTimeout(() => setCopiedIndex(null), 2000);
  };

  return (
    <Base hideDatepicker={true} header_title="HSN">
      <div className="mt-4 d-flex justify-content-end position-relative">
      {roleId === 1 && (
        <button
          className="p-0 d-flex align-items-center border-0 bg-transparent"
          onClick={() => setShowHierarchy(true)}
        >
          <FaCogs className="mr-2" style={{ color: "#0d6efd" }} />
          <span style={{ color: "#0d6efd" }}>HSN/SAC Approval Hierarchy</span>
        </button>
        )}
        {success && (
          <div className="alert-overlay-absolute">
            <Alert dismissible variant="success">
              <p>{successMsg}</p>
            </Alert>
          </div>
        )}
      </div>
      <div className="mt-4">
        <Tabs
          fill
          transition={false}
          activeKey={activeTab}
          onSelect={(key) => {
            setActiveTab(key);
            setCurrentPage(1);
            dispatch(resetHsnSacState());
            setQuery("");

          }}
          className="mb-4"
          style={{
            background: "#eaeded",
            boxShadow: "0px 4px 6px  rgba(0, 0, 0, 0.1)",
            '--bs-nav-tabs-link-active-bg': 'white',
            '--bs-nav-tabs-link-active-color': '#0d4473',
          }}
        >
          <Tab eventKey="HSN" title="HSN">
            <Row className="align-items-center py-3 px-2 mb-4 bg-light rounded shadow-sm">
              <Col className="d-flex align-items-center gap-2">
                <Button
                  variant="primary"
                  onClick={() => setShowBulkImport(true)}
                >
                  Bulk Import
                </Button>
                <Button variant="primary" onClick={() => handleAddClick()}>
                  Add New
                </Button>
                <Form.Control
                  type="text"
                  placeholder="Search HSN or Description..."
                  className="w-auto"
                  value={query}
                  onChange={handleSearchChange}
                />
              </Col>
              <Col className="d-flex justify-content-end">
                <Form.Label className="fw-bold mb-0" style={{ color: "#0d4473" }}>Total number of HSN: {total_count}</Form.Label>
              </Col>
            </Row>
            <Table striped>
              <thead>
                <tr>
                  <th>HSN</th>
                  <th>Description</th>
                  <th>Rate</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      <Spinner
                        animation="border"
                        role="status"
                        variant="primary"
                      ></Spinner>
                    </td>
                  </tr>
                )}
                {!loading && renderTableContent("HSN")}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="SAC" title="SAC">
            <Row className="align-items-center py-3 px-2 mb-4 bg-light rounded shadow-sm">
              <Col className="d-flex align-items-center gap-2">
                <Button
                  variant="primary"
                  onClick={() => setShowBulkImport(true)}
                >
                  Bulk Import
                </Button>
                <Button
                  variant="primary"
                  onClick={() => setShowAddEditModal(true)}
                >
                  Add New
                </Button>
                <Form.Control
                  type="text"
                  placeholder="Search SAC or Description..."
                  className="w-auto"
                  value={query}
                  onChange={handleSearchChange}
                />
              </Col>
              <Col className="d-flex justify-content-end">
                <Form.Label className="fw-bold mb-0" style={{ color: "#0d4473" }}>Total number of SAC: {total_count}</Form.Label>
              </Col>
            </Row>
            <Table striped>
              <thead>
                <tr>
                  <th>SAC</th>
                  <th>Description</th>
                  <th>Rate</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      <Spinner
                        animation="border"
                        role="status"
                        variant="primary"
                      ></Spinner>
                    </td>
                  </tr>
                )}
                {!loading && renderTableContent("SAC")}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="QUERIES" title="Queries">
            <Row className="align-items-center py-3 px-2 mb-4 bg-light rounded shadow-sm">
              <Col className="d-flex align-items-center gap-2">
                {/* <Form.Control
                  type="text"
                  placeholder="Search Queries"
                  className="w-auto"
                  value={query}
                  onChange={handleSearchChange}
                /> */}
                <Form.Select
                  className="w-auto"
                  value={selectedStatus}
                  onChange={handleStatusChange}
                >
                  <option value="">All Status</option>
                  <option value="Completed">Completed</option>
                  <option value="Pending">Pending</option>
                </Form.Select>
              </Col>
              <Col className="d-flex justify-content-end">
                <Form.Label className="fw-bold mb-0" style={{ color: "#0d4473" }}>Total number of Queries: {total_count}</Form.Label>
              </Col>
            </Row>
            <Table striped>
              <thead>
                <tr>
                <th>Index</th>
                  <th>Client</th>
                  <th>Description</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {queryList?.length > 0 ? (
                  queryList.map((query, index) => (
                    <tr
                      key={index}
                      onClick={() => handleQueryClick(query)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{index + 1}</td>
                      <td>{query.client_details?.name}</td>
                      <DescriptionCell
                        description={query.query}
                        progressbar={false}
                      />
                      <td>{query?.type || "NA"}</td>
                      <td>{query.status === 1 ? "Completed" : "Pending"}</td>
                      <td>{query.updated_time_formatted.split("T")[0]}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="100%"
                      style={{ textAlign: "center", padding: "20px" }}
                    >
                      No data available
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="HSN_PENDING" title="HSN Pending">
            <Row className="align-items-center py-3 px-2 mb-4 bg-light rounded shadow-sm">
              <Col className="d-flex align-items-center gap-2">
                <Form.Control
                  type="text"
                  placeholder="Search hsn..."
                  className="w-auto"
                  value={query}
                  onChange={handleSearchChange}
                />
                <Button
                  variant="success"
                  disabled={selectedRows.length === 0}
                  onClick={() => handleApproveReject(selectedRows, 1)}
                >
                  Bulk Approve
                </Button>
                <Button
                  variant="danger"
                  disabled={selectedRows.length === 0}
                  onClick={() => handleApproveReject(selectedRows, 2)}
                >
                  Bulk Reject
                </Button>
              </Col>
            </Row>
            <Table striped>
              <thead>
                <tr>
                  <th>Selection</th>
                  <th>HSN</th>
                  <th>Description</th>
                  <th>Rate</th>
                  <th>Action Type</th>
                  <th>Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      <Spinner
                        animation="border"
                        role="status"
                        variant="primary"
                      ></Spinner>
                    </td>
                  </tr>
                )}
                {!loading && renderPendingTableContent("HSN")}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="SAC_PENDING" title="SAC Pending">
            <Row className="align-items-center py-3 px-2 mb-4 bg-light rounded shadow-sm">
              <Col className="d-flex align-items-center gap-2">
                <Form.Control
                  type="text"
                  placeholder="Search SAC..."
                  className="w-auto"
                  value={query}
                  onChange={handleSearchChange}
                />
                <Button
                  variant="success"
                  disabled={selectedRows.length === 0}
                  onClick={() => handleApproveReject(selectedRows, 1)}
                >
                  Bulk Approve
                </Button>
                <Button
                  variant="danger"
                  disabled={selectedRows.length === 0}
                  onClick={() => handleApproveReject(selectedRows, 2)}
                >
                  Bulk Reject
                </Button>
              </Col>
            </Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Selection</th>
                  <th>SAC</th>
                  <th>Description</th>
                  <th>Rate</th>
                  <th>Date</th>
                  <th>Type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan="5" style={{ textAlign: "center" }}>
                      <Spinner
                        animation="border"
                        role="status"
                        variant="primary"
                      ></Spinner>
                    </td>
                  </tr>
                )}
                {!loading && renderPendingTableContent("SAC")}
              </tbody>
            </Table>
          </Tab>
        </Tabs>
        <PaginationComponent
          total_count={total_count}
          current_page={current_page}
          handleOnClick={handlePagination}
          per_page={per_page}
        />
      </div>
      <BulkImportModal
        show={showBulkImport}
        onHide={() => {setShowBulkImport(false); setActiveTab(activeTab);}}
        type={activeTab}
      />
      <AddEditHsnSacModal
        show={showAddEditModal}
        onHide={() => {
          setShowAddEditModal(false);
          setEditData(null);
          setIsUpdate(false);
          setCurrentPage(1);
          setActiveTab(activeTab);
        }}
        data={editData}
        isUpdate={isUpdate}
        activeTab={activeTab}
      />

      <RoleSelectionPopup
        show={showHierarchy}
        onHide={() => {setShowHierarchy(false); setActiveTab(activeTab);}}
      />
    </Base>
  );
};

export default HsnSacComponent;
