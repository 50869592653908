import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import toast from 'react-hot-toast';

const UpdatePasswordModal = ({showModal, handleModalClose, selectedUser, submitAction}) => {
    const dispatch = useDispatch();
    const { successMsg, error:errorMsg } = useSelector(state => state.user);
    const [formData, setFormData] = useState({
        newPassword: '',
        repeatPassword: '',
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors({});
        let validationErrors = {}; // Temporary object to store all errors

        // Check if new password is empty
        if (!formData.newPassword || formData.newPassword.trim() === '') {
            validationErrors.newPassword = 'New Password field is empty';
        }

        // Check if repeat password is empty
        if (!formData.repeatPassword || formData.repeatPassword.trim() === '') {
            validationErrors.repeatPassword = 'Repeat New Password field is empty';
        }

        // Check if new password matches repeat password
        if (formData.newPassword !== formData.repeatPassword) {
            validationErrors.general = 'Passwords do not match!';
        }

        // If there are any errors, set them and return
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Stop the form submission
        }
        submitAction(formData, selectedUser.id);
       
    };

    useEffect(() => {
        setErrors({});
        setFormData({
            newPassword: '',
            repeatPassword: '',
        });

    },[showModal, successMsg])
  return (
    <Modal size="xl" show={showModal} onHide={handleModalClose} backdrop="static" enforceFocus={false}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedUser.name}: Update Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
                <Col xl={12} lg={12}>
                    <div className="new-user-info">
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col md={6}>
                                    <Form.Group controlId="newPassword">
                                        <Form.Label>New Password:</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="New Password"
                                            value={formData.newPassword}
                                            onChange={handleChange}
                                            isInvalid={!!errors.newPassword}
                                        />
                                        {errors.newPassword && (
                                            <Form.Control.Feedback type="invalid">
                                                {errors.newPassword}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>

                                <Col md={6}>
                                    <Form.Group controlId="repeatPassword">
                                        <Form.Label>Repeat New Password:</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Repeat Password"
                                            value={formData.repeatPassword}
                                            onChange={handleChange}
                                            isInvalid={!!errors.repeatPassword}
                                        />
                                        {errors.repeatPassword && (
                                            <Form.Control.Feedback type="invalid">
                                                {errors.repeatPassword}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                            {errors.general || errorMsg ? (
                                <div className="alert alert-danger mt-3 border-0">
                                    {errors.general || errorMsg}
                                </div>
                            ) : ''}
                            {successMsg && (
                                <div className="alert alert-success mt-3 border-0">
                                    {successMsg}
                                </div>
                            )}
                            <Button type="submit" className="btn btn-primary mt-3">
                                Submit
                            </Button>
                        </Form>
                    </div> 
                </Col>
            </Row>
        </Modal.Body>
    </Modal>
  )
}

export default UpdatePasswordModal
