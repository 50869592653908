import { Outlet, Navigate } from 'react-router-dom'
import {  useDispatch, useSelector } from 'react-redux';
import { getUserDetails } from '../slices/commonReducer';
import { useEffect } from 'react';

const PrivateRoutes = (props) => {
    const token  = localStorage.getItem('token');
    const dispatch = useDispatch();
    const {permissions} = useSelector(state => state.common)

    let auth = {'token': token ? true : false}
    useEffect(() => {
        if (!permissions.length && token)
            dispatch(getUserDetails())
    }, [permissions, token])

    return(
        auth.token ? (permissions?.length ? <Outlet/>: '') : <Navigate to="/login"/>
    )
}

export default PrivateRoutes