import { useEffect, useState, useRef } from "react"
import { 
    Container, 
    Row, 
    Col, 
    Breadcrumb, 
    Alert,
    Card,
    Button,
    Table,
    Modal,
    Form,
    InputGroup
 } from "react-bootstrap"
import { 
    NavLink, 
    useParams, 
    useSearchParams, 
    useNavigate 
} from "react-router-dom"
import PaginationComponent from "../common/Pagination"
import { useSelector, useDispatch } from "react-redux"
import { 
    resetStore,
    getAssignedClients,
    getUnassignedClients,
    assignClients,
    removeClient
} from "../../slices/userReducer"
import { PER_PAGE } from "../../constants/user"



export default function AssignClient() {
    const dispatch = useDispatch()
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedClients, setSelectedClients ] = useState([])
    const [errorMsg, setErrorMsg] = useState('')
    const [removeClientId, setRemoveClientId] = useState(null)
    const [removeClientIdModalShow, setRemoveClientIdModalShow] = useState(false)
    const searchRef = useRef();

    const [current_page, setCurrentPage] = useState(1)
    const [unassigned_current_page, setUnassignedCurrentPage] = useState(1)
    const {
        successMsg,
        success,
        error,
        assigned_clients,
        unassigned_clients, 
        total_unassigned_count,
        total_assigned_count,
    } = useSelector(state => state.user)
    const [show, setShow] = useState(false)

    useEffect(() => {
        dispatch(resetStore());
        fetchAssignClients();
    },[])


    const fetchAssignClients = () => {
        if (params.user_id) {
            const data ={ page: current_page, perpage: PER_PAGE}
            dispatch(getAssignedClients({id: params.user_id, data}))
        }

    }

    useEffect(() => {
    
        if (searchParams.get('add_client')) {
            setShow(true);
            const data ={ page: unassigned_current_page, perpage: PER_PAGE}
            dispatch(getUnassignedClients({id: params.user_id, data}))
        } else {
            setShow(false);
        }
    }, [searchParams])

    const handleClose = () => {
        if (searchParams.has('add_client')) {
            searchParams.delete('add_client');
            setSearchParams(searchParams);
        }
    }

    const handleClientSearch = () => {

        const searchText = searchRef.current.value.trim();
        const data ={ page: current_page, perpage: PER_PAGE}
        if (searchText) {
            data['q'] = searchText
        }

        dispatch(getUnassignedClients({id: params.user_id, data}))

    }

    const handleUnAssignPageChange = (number) => {
            setUnassignedCurrentPage(number)
            const data ={ page: number, perpage: PER_PAGE}
            dispatch(getUnassignedClients({id: params.user_id, data}))
            setSelectedClients([])
    }

    const handleAssignPageChange = (number) => {
        setCurrentPage(number)
        const data ={ page: number, perpage: PER_PAGE}
        dispatch(getAssignedClients({id: params.user_id, data}))
    }

    const handleSelect = (e, selected = 'all') => {
        let selectedIds = []
        if ( 'all' == selected ) {
            
            if (e.target.checked)
                selectedIds = unassigned_clients.map(client => client.id)
           
        } else {
            selectedIds = [...selectedClients]
            if (e.target.checked) {
                
            }
            selectedIds = e.target.checked ? [...selectedIds ,selected] : selectedIds.filter(id => selected != id)

        }
        setSelectedClients(selectedIds)

    }

    const handleSave = () => {
       let msg = ''
       if (selectedClients.length == 0) {
        msg = 'Please select atleast one client'
       } 
       
       if (selectedClients.length > 0 ) {
            const data = {client_ids: selectedClients}
            dispatch(assignClients({id: params.user_id, data}))
       }
       setErrorMsg(msg)
       setSelectedClients([])
       fetchAssignClients()
       handleClose()  
    }

    const handleClientRemoveClick = (client_id) => {
        setRemoveClientId(client_id)
        setRemoveClientIdModalShow(true)
    }

    const handleRemoveClientClose = () => {
        setRemoveClientId(null)
        setRemoveClientIdModalShow(false)
    }

    const handleRemoveClient = () => {
        const data = {client_id: removeClientId}
        if (params.user_id) {
            dispatch(removeClient({id: params.user_id, data}))
        }
        fetchAssignClients()
        handleRemoveClientClose()
    }
    
    return (<Container>
        <Row>
            <Col
                className="mr-auto text-center"
            >
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: '/' }}  >Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: '/user-management' }}>
                        Users
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        Assign Client
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Col>
        </Row>
        {(success || error ) && (<Row className="justify-content-md-center">
        <Col md={3}>
        <Alert 
        dismissible
        variant={success ? 'success' : 'danger'}
        onClose={() => dispatch(resetStore())}
        >
        <p>
        {success ? successMsg : error}
        </p>
    </Alert></Col>
    
    </Row>)}
        <Row>
            <Col 
            
            >
                <Card>
                    <Card.Header>
                        <Row>
                            <Col
                                xs={10}
                                lg={10}
                                md={10}
                            >
                                <div className="header-title">
                                    <Card.Title as="h4">Assigned Client List</Card.Title>
                                </div>
                            </Col>
                            <Col
                              xs={2}
                              lg={2}
                              md={2}  
                            >
                                <NavLink to={`?add_client=true`}><Button>Assign Client</Button></NavLink>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body>
                        <Row><Col>
                        <Table responsive striped>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>GSTIN</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {Array.isArray(assigned_clients) && assigned_clients.length > 0 && assigned_clients.map(client => (<tr>
                                        <td>{client.client_name}</td>
                                        <td>{client.gstin}</td>
                                        <td><i title="Remove Client" style={{cursor: 'pointer'}} onClick={() => handleClientRemoveClick(client.id)} class="ri-link-unlink-m font-size-20"></i></td>
                                    </tr>
                                
                                )) }
                                
                                </tbody>
                        </Table>
                        {((Array.isArray(assigned_clients) && assigned_clients < 1) || (!Array.isArray(assigned_clients))) && <><br/><b>No Assigned Clients Found</b></> }
                        </Col></Row>
                    </Card.Body>
                    {total_assigned_count > PER_PAGE &&<Card.Footer>
                        <PaginationComponent
                        per_page={PER_PAGE}
                        current_page={current_page}
                        total_count={total_assigned_count}
                        handleOnClick={handleAssignPageChange}
                        />
                    </Card.Footer>}
                    
                </Card>
            </Col>
        </Row>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Assign Client</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Card>
                    <Row>
                        <Col md={12} className="d-flex justify-content-center">
                            <span style={{color: 'red'}}>{errorMsg}</span>
                        </Col>
                        <Col md={12}>
                            <Form.Group
                                className="form-group mb-0"
                            >
                                <InputGroup>
                                    <Form.Control
                                        placeholder="Search Client By Name"
                                        ref={searchRef}
                                    />
                                    <Button variant="primary" onClick={handleClientSearch} ><i className="ri-search-line"></i></Button>
                                </InputGroup>
                            </Form.Group>
                            </Col>
                            <Col md={12} className="mt-2">
                            <Table>
                                <thead>
                                    <tr>
                                    <th role="col">
                                            <input type="checkbox" checked={unassigned_clients.length == selectedClients.length} onChange={(e) => handleSelect(e, 'all')}  />
                                    </th>
                                    
                                        <th role="col">
                                            Client Name
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.isArray(unassigned_clients) && unassigned_clients.length > 0 && unassigned_clients.map(client => { 
                                        return(<tr key={client.id}><td ><input type="checkbox" checked={selectedClients.includes(client.id)} onChange={(e) => handleSelect(e, client.id)} /></td><td>{client.client_name}</td></tr>)})}
                                </tbody>
                            </Table>
                        </Col>
                        <Col
                         md={12}   
                        >
                        <PaginationComponent
                        per_page={PER_PAGE}
                        current_page={unassigned_current_page}
                        total_count={total_unassigned_count}
                        handleOnClick={handleUnAssignPageChange}
                        />
                        </Col >
                    </Row>
                    
                </Card>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={handleSave}>SAVE</Button>
            </Modal.Footer>
        </Modal>
        <Modal show={removeClientIdModalShow} onHide={handleRemoveClientClose}>
              <Modal.Header closeButton>
                <Modal.Title>Remove Client</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Are you sure you want to remove client?
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={handleRemoveClient}>OK</Button>
              </Modal.Footer>                          
        </Modal>
    </Container>)
}