// YearMonthContext.js
import moment from 'moment';
import React, { createContext, useContext, useState } from 'react';
import { CURRENT_MONTH_ENABLE_DAY } from '../constants/common'

// Create the context
const YearMonthContext = createContext();

// Create a provider component
export const YearMonthProvider = ({ children }) =>  {const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth() + 1; // `getMonth()` is 0-based, so add 1
  const currentDate = today.getDate();

  // Determine effective year (handles year change logic based on `CURRENT_MONTH_ENABLE_DAY`)
  const effectiveYear = currentMonth === 1 && currentDate < CURRENT_MONTH_ENABLE_DAY? (currentYear - 1).toString(): currentYear.toString();

  const effectiveMonth =currentDate > CURRENT_MONTH_ENABLE_DAY ? currentMonth : currentMonth - 1;

  // Ensure month stays within bounds (handles case when month goes below 1)
  const finalMonth = effectiveMonth < 1 ? 12 : effectiveMonth;
    
  const [selectedYear, setSelectedYear] = useState(effectiveYear);
  const [selectedMonth, setSelectedMonth] = useState(moment().month(finalMonth - 1).format("MM")); // `month()` is 0-based

  return (
    <YearMonthContext.Provider value={{ selectedYear, setSelectedYear, selectedMonth, setSelectedMonth }}>
      {children}
    </YearMonthContext.Provider>
  );
};

// Custom hook to use the YearMonthContext
export const useYearMonth = () => useContext(YearMonthContext);
