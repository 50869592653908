import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "./hsn.css";
import {
  HSN_SAC_CREATION_FIELDS,
  HSN_SAC_CREATION_SCHEMA,
} from "../../constants/hsnSac";
import {
  createHsnSac,
  updateHsnSac,
  getHsnSac,
  resetHsnSacState
} from "../../slices/hsnSacReducer";
import { useSelector,useDispatch } from "react-redux";
import Alert from "react-bootstrap/Alert";

const AddEditHsnSacModal = ({ show, onHide, data, isUpdate, activeTab }) => {
  const { error} = useSelector((state) => state.hsnSac);
  const dispatch = useDispatch();
  const initialFormData = {
    hsn_number: "",
    description: "",
    types: activeTab || "",
    rate: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (show) {
      setFormData(data || initialFormData);
      setErrors({}); // Clear errors when the modal opens
    }
  }, [show, data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear error for the field being updated
    }));
  };

  const validateFields = async () => {
    try {
      await HSN_SAC_CREATION_SCHEMA.validate(formData, { abortEarly: false });
      setErrors({}); 
      return true;
    } catch (validationErrors) {
      const errorMessages = {};
      validationErrors.inner.forEach((err) => {
        errorMessages[err.path] = err.message;
      });
      setErrors(errorMessages);
      return false;
    }
  };

  const handleSubmit = async () => {
    const isValid = await validateFields();
    console.log(isValid)
    if (!isValid) return;

    const payload = { ...formData };
    try {
      if (!isUpdate) {
        await dispatch(createHsnSac({ data: payload })).unwrap();
      } else {
        await dispatch(updateHsnSac({ data: { ...payload } })).unwrap();
      }
      dispatch(getHsnSac({ types: activeTab }));
      setFormData(initialFormData);
      onHide();
    } catch (error) {
      console.error("Submission Error:", error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName="custom-modal-height"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{isUpdate ? "Update" : "Add New"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      {(error != null) && (
          <div className="row">
            <div className="col-md-12 justify-content-center d-flex">
              <Alert
                dismissible
                variant={ "danger"}
                onClose={() => dispatch(resetHsnSacState())}
              >
                <p>{error}</p>
              </Alert>
            </div>
          </div>
        )}
        <Form>
          {Object.entries(HSN_SAC_CREATION_FIELDS).map(([key, field]) => (
            <Form.Group className="mb-3" key={key}>
              <Form.Label>
                {field.label === "HSN" ? activeTab : field.label}
              </Form.Label>
              <Form.Control
                type={field.types}
                name={field.name}
                value={formData[field.name] || ""}
                onChange={handleChange}
                placeholder={field.placeholder}
                readOnly={field.name === "types"}
                isInvalid={!!errors[field.name]} 
              />
              <Form.Control.Feedback type="invalid">
                {errors[field.name]}
              </Form.Control.Feedback>
            </Form.Group>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setFormData(initialFormData);
            onHide();
          }}
        >
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {isUpdate ? "Update" : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEditHsnSacModal;
