import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate, NavLink } from "react-router-dom"
import { Breadcrumb } from "react-bootstrap"

import { 
    getPendingClients,
    resetStore,
    updatePendingClientStatus
} from "../slices/clientReducer"
import Loader from "./common/Loader";
import Base from "./Base"
import Alert from 'react-bootstrap/Alert';
import PaginationComponent from "./common/Pagination"
import { TABLE_HEADERS } from "../constants/clients"
import { PAGE_PERMISSIONS, USER_ROLES } from "../constants/user"
import useCheckAuthorization from "../hooks/checkAuthorization"
import PendingListView from "./common/PendingListView"


export default function PendingClients(){

    const [current_page, setCurrentPage] = useState(1);
    const { 
        pendingData,
        loading,
        error,
        success,
        successMsg,
        total_pending_count
     } = useSelector((state) => state.clients)
    const {role_id} = useSelector(state => state.common)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const hasPermission = useCheckAuthorization(PAGE_PERMISSIONS.client)
    const isAdmin = role_id === USER_ROLES.admin
    const gridView = 'list';

    useEffect(()=> {
        if (!hasPermission) {
            navigate('/authorization-error');
        }
        dispatch(getPendingClients(current_page));
        return () => dispatch(resetStore())
    }, [])


    const handlePendingClientApprove = (client_id, active) =>{
      dispatch(updatePendingClientStatus({active, client_id}))
    }

    const handlePagination = (number) => {
        setCurrentPage(number)
        dispatch(getPendingClients(number));
    }

    return (<Base sticky_footer={false} header_title={'Clients'} hideDatepicker={true} showSearchIcon={true}>
                <div className="row mb-2">
                    <div>
                      <div className="row">
                      <div className="col-md-4 col-sm-4 col-lg-4 mr-auto text-center">
                            <Breadcrumb>
                                <Breadcrumb.Item linkAs={NavLink} linkProps={{to: '/'}}>Dashboard</Breadcrumb.Item>
                                <Breadcrumb.Item active>Pending Clients</Breadcrumb.Item>
                            </Breadcrumb>
                            </div>      
                            
                    </div>
                    </div>
                </div>  
                 {(success || error ) && (
                  <div className="row ">
                    <div className="col-md-12 justify-content-center d-flex">  
                        <Alert 
                            dismissible
                            variant={success ? 'success' : 'danger'}
                            onClose={() => dispatch(resetStore())}
                        >
                            <p>
                            {success ? successMsg : error}
                            </p>
                        </Alert>
                    </div>
                  </div>)}
                <div className="row">  
                    <div className="icon"  style={{display: gridView == 'list' ? 'block' : 'none'}}>
                        <PendingListView
                         headers={TABLE_HEADERS}
                         data={Array.isArray(pendingData) ? pendingData: []}
                         handlePendingClientApprove = {handlePendingClientApprove}
                         isAdmin={isAdmin}
                       />
                    </div>
                </div>  
                <div className="row mt-10">
                    <div className="col-md-12 d-flex justify-content-center">
                    <PaginationComponent total_count={total_pending_count} current_page={current_page} handleOnClick={handlePagination}/>
                    </div>
                </div>
                {loading && (<Loader/>)}
                
            </Base>)
}
