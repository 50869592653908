import { 
    Row,
    Col,
    Container,
    Card,
    CardHeader,
    Button,
    Form,
    CardBody,
    Table,
    FormCheck, 
    Alert,
    CardFooter,
    Breadcrumb, 
    InputGroup,
    Tabs, 
    Tab
  } from "react-bootstrap";
  import { useEffect, useState, useRef } from "react";
  import { useSelector, useDispatch } from "react-redux";
  
import { NavLink, useNavigate } from "react-router-dom";
import { getUsers, updateStatus, resetStore, updatePassword, getPendingUsers, updatePendingUserStatus } from "../../slices/userReducer";
import { CURRENT_PAGE, PER_PAGE, USER_ROLES } from "../../constants/user";
import PaginationComponent from "../common/Pagination";
import useCheckAuthorization from "../../hooks/checkAuthorization";
import { PAGE_PERMISSIONS, CRUD_PERMISSIONS, RESTRICT_ASSIGN_ROLES } from "../../constants/user";
import UpdatePasswordModal from "./UpdatePasswordModal";

export default function UserList() {
    const [current_page, setCurrentPage] = useState(CURRENT_PAGE)
    const searchRef = useRef()
    const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');
    const [activeTab, setActiveTab] = useState("userList");
    const { 
        data,
        pendingData,
        error,
        success,
        successMsg,
        total_count,
        total_pending_count
    } = useSelector((state) => state.user)
    const dispatch = useDispatch();

    const navigate = useNavigate();
  
  
    const hasPermission = useCheckAuthorization(PAGE_PERMISSIONS.user)
    const addPermission = useCheckAuthorization(CRUD_PERMISSIONS.user.add)
    const disablePermission =  useCheckAuthorization(CRUD_PERMISSIONS.user.delete)
    const editPermission= useCheckAuthorization(CRUD_PERMISSIONS.user.edit)
    const assignPermission = useCheckAuthorization(CRUD_PERMISSIONS.user.assign)
    const {role_id} = useSelector(state => state.common)
    const isAdmin = role_id === USER_ROLES.admin
    
    const handleUpdatePasswordModalClose = async () => {
      dispatch(resetStore());
      setShowUpdatePasswordModal(false);
    }

    const updatePasswordSubmit = async (formData, id) => {
      const {newPassword: new_password, repeatPassword: confirm_new_password} = formData;
      dispatch(updatePassword({data:{new_password, confirm_new_password}, id}));
    }
  
    const handleTabSelect = (tabName) => {
      console.log("Selected tab:", tabName);
      setActiveTab(tabName);
    };

    const handlePendingUserApprove = (id, active) => {
      dispatch(updatePendingUserStatus({id, active}));
    }

    useEffect(() => {
  
      if (!hasPermission) {
        navigate('/authorization-error');
      }
    }, [hasPermission])  


    useEffect(() => {
      setCurrentPage(CURRENT_PAGE)
      if(activeTab === 'userList')
        dispatch(getUsers({page: current_page}))
      if(activeTab === 'pendingUsers')
        dispatch(getPendingUsers({page: current_page}))
    },[activeTab])

    const switchToggle = (id, e) => {
        dispatch(updateStatus({id, active:  e.target.checked, page: current_page}))
    }

    const handlePagination = (number) => {
        setCurrentPage(number)
        if(activeTab === 'userList')  
          dispatch(getUsers({page: number}))
        if(activeTab === 'pendingUsers')  
          dispatch(getPendingUsers({page: number}))
    }

    const handleSearch = () => {
      const searchText = searchRef.current.value.trim();
      if (searchText) {
        if(activeTab === 'userList'){
          dispatch(getUsers({page: current_page, search: searchText}))
        }else if(activeTab === 'pendingUsers'){
          dispatch(getPendingUsers({page: current_page, search: searchText}))
        }
      } else {
        if(activeTab === 'userList'){
          dispatch(getUsers({page: current_page}))
        }else if(activeTab === 'pendingUsers'){
          dispatch(getPendingUsers({page: current_page}))
        }
        
      }
    }

    useEffect(() => {
      dispatch(resetStore());
    },[])

    return (
      <Container>
        <Row>
          <Col className="mr-auto text-center">
            <Breadcrumb>
              <Breadcrumb.Item linkAs={NavLink} linkProps={{ to: '/' }}>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item active>Users</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        {(success || error) && (
          <Row className="justify-content-md-center">
            <Col md={3}>
              <Alert dismissible variant={success ? 'success' : 'danger'} onClose={() => dispatch(resetStore())}>
                <p>{success ? successMsg : error}</p>
              </Alert>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={12}>
            <Card>
              <CardHeader className="d-flex justify-content-between">
                <Col sm={3} md={3}>
                  <div className="header-title">
                    <Card.Title>User Management</Card.Title>
                  </div>
                </Col>
                <Col sm={3} md={3} className="text-right">
                  {addPermission && (
                    <NavLink to="add-user">
                      <Button variant="primary"><span>+</span> Add New</Button>
                    </NavLink>
                  )}
                </Col>
                <Col sm={6} md={6} className="text-right">
                  <div id="user_list_datatable_info" className="dataTables_filter">
                    <Form.Group className="form-group mb-0">
                      <InputGroup>
                        <Form.Control placeholder="Search" ref={searchRef} />
                        <Button variant="primary" onClick={handleSearch}><i className="ri-search-line"></i></Button>
                      </InputGroup>
                    </Form.Group>
                  </div>
                </Col>
              </CardHeader>
              
              <Tabs defaultActiveKey="userList" activeKey={activeTab} onSelect={handleTabSelect} id="user-management-tabs" className="mb-3">
                <Tab eventKey="userList" title="User List">
                  <CardBody>
                    <Col>
                      {!!data.length ? (
                        <Table responsive bordered striped id="user-list-table" className="mt-4">
                          <thead>
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Contact</th>
                              <th scope="col">Email</th>
                              <th scope="col">Role</th>
                              <th scope="col">Status</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((user) => (
                              <tr key={user.id}>
                                <td>{user.name}</td>
                                <td>{user.phone}</td>
                                <td>{user.email}</td>
                                <td>{user.role.name}</td>
                                <td className="align-middle">
                                  <FormCheck
                                    inline
                                    id="userStatus"
                                    type="switch"
                                    defaultChecked={user.active}
                                    onChange={(e) => switchToggle(user.id, e)}
                                    disabled={!disablePermission}
                                  />
                                </td>
                                <td>
                                  <Row>
                                    <Col md={2}>
                                      {editPermission && (
                                        <NavLink to={`edit-user/${user.id}`}>
                                          <Button variant="link" className="dropdown-item" title="Edit User">
                                            <i className="ri-pencil-fill mr-2"></i>
                                          </Button>
                                        </NavLink>
                                      )}
                                    </Col>
                                    <Col md={2}>
                                      {editPermission && (
                                        <Button
                                          variant="link"
                                          className="dropdown-item"
                                          title="Update Password"
                                          onClick={() => { setSelectedUser(user); setShowUpdatePasswordModal(true); dispatch(resetStore());}}
                                        >
                                          <i className="ri-settings-3-line mr-2"></i>
                                        </Button>
                                      )}
                                    </Col>
                                    {!RESTRICT_ASSIGN_ROLES.includes(user.role.id) && assignPermission && (
                                      <Col md={2}>
                                        <NavLink to={`assign-client/${user.id}`}>
                                          <Button variant="link" className="dropdown-item" title="Assign Clients">
                                            <i className="ri-links-line mr-2"></i>
                                          </Button>
                                        </NavLink>
                                      </Col>
                                    )}
                                  </Row>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        'No Users Found'
                      )}
                    </Col>
                  </CardBody>
                </Tab>
                
                <Tab eventKey="pendingUsers" title="Pending Users">
                <CardBody>
                    <Col>
                      {!!pendingData.length ? (
                        <Table responsive bordered striped id="user-list-table" className="mt-4">
                          <thead>
                            <tr>
                              <th scope="col">Name</th>
                              <th scope="col">Contact</th>
                              <th scope="col">Email</th>
                              <th scope="col">Role</th>
                              <th scope="col">Status</th>
                              {isAdmin && <th scope="col">Action</th> }
                            </tr>
                          </thead>
                          <tbody>
                            {pendingData.map((user) => (
                              <tr key={user.id}>
                                <td>{user.name}</td>
                                <td>{user.phone}</td>
                                <td>{user.email}</td>
                                <td>{user.role.name}</td>
                                <td className="align-middle">
                                  <FormCheck
                                    inline
                                    id="userStatus"
                                    type="switch"
                                    defaultChecked={user.active}
                                    onChange={(e) => switchToggle(user.id, e)}
                                    disabled={!disablePermission}
                                  />
                                </td>
                                {isAdmin &&
                                  <td>
                                  
                                    
                                      {editPermission && (
                                        <>
                                      <Button
                                      variant="primary"
                                      size="sm"
                                      className="py-0 px-1 !text-[12px]"
                                      onClick={() => handlePendingUserApprove(user.id, true)}
                                      >
                                        Approve
                                      </Button>
                                      <span className="mx-1">|</span>
                                      <Button
                                      variant="outline-primary"
                                      size="sm"
                                      className="py-0 px-1 !text-[12px]"
                                      onClick={() => handlePendingUserApprove(user.id, false)}
                                      >  
                                       Reject
                                      </Button>
                                      </>
                                      )}
                                   
                                  
                                  </td>
                                }
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      ) : (
                        'No Pending Users Found'
                      )}
                    </Col>
                  </CardBody>
                </Tab>
              </Tabs>
              
              <CardFooter>
                <Row>
                  <Col sm={12} md={12} lg={12} className="d-flex justify-content-end">
                    <PaginationComponent
                      total_count={activeTab === 'userList' ? total_count: total_pending_count}
                      current_page={current_page}
                      handleOnClick={handlePagination}
                      per_page={PER_PAGE}
                    />
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        <UpdatePasswordModal showModal={showUpdatePasswordModal} selectedUser={selectedUser} handleModalClose={handleUpdatePasswordModalClose} submitAction={updatePasswordSubmit} />
      </Container>
    );
}