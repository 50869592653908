import { Fragment } from "react"
import { useSelector, useDispatch } from "react-redux";
import logo from '../../logo.png';
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "../../services/baseService";
import { setSearch } from "../../slices/commonReducer";

function Sidebar() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { data } = useSelector((state) => state.clients)

    const handleSignOut = () => {
        signOut().then(response => {
            navigate('/login');
        })
    }

    const enableSearch = () => {
        dispatch( setSearch(true) )
    }
    return (
        <Fragment>
            <div className="iq-sidebar  sidebar-default ">
            <div className="iq-sidebar-logo d-flex align-items-center justify-content-between">
                    <Link to="/" className="header-logo">
                    <img src={logo} className="img-fluid rounded-normal light-logo" alt="logo" />
                    </Link>
                <div className="iq-menu-bt-sidebar">
                    <i className="las la-bars wrapper-menu"></i>
                </div>
            </div>
            <div className="data-scrollbar" data-scroll="1">
                <nav className="iq-sidebar-menu">
                    <ul id="iq-sidebar-toggle" className="iq-menu">
                        <li className=" ">
                                <Link style={{textDecoration: 'none'}} to="/">
                                    <i className="las la-home iq-arrow-left"></i><span>Dashboard</span>
                                </Link>
                            <ul id="dashboard" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                            </ul>
                        </li>
                        <li className=" ">
                            <Link to="/pending-clients">
                                <i className="las la-hdd"></i><span>Pending Clients</span>
                                </Link>
                        </li>
                        <li className="" >
                            <Link to="/manage-profile">
                                <i className="las la-user-cog iq-arrow-left"></i><span>Manage Profile</span>
                            </Link>
                        </li>
                        <li className="" >
                                <Link>
                                    <i className="las la-stopwatch iq-arrow-left"></i><span>Recent</span>
                                </Link>
                            <ul id="page-files" className="iq-submenu collapse" data-parent="#iq-sidebar-toggle">
                            </ul>
                        </li>
                       
                        <li className="sidebar-search">
                            <Link onClick={enableSearch}>
                            <i className="ri-search-line"></i> <span>Search</span>
                            </Link>  
                        </li>
                    </ul>
                </nav>
                <div className="sidebar-bottom">
                  <Button 
                    onClick={handleSignOut}
                    variant="outline-primary"
                    size="lg"
                    className="view-more"
                  >Sign Out</Button>
              </div>
            </div>
            </div>
        </Fragment>      
    );
}

export default Sidebar;