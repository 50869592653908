import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import './XlsxTable.css';
import { Button, Modal} from 'react-bootstrap';
import instance from "../services/baseService";
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import NoteModal from "./common/NoteModal";
import { useParams } from "react-router-dom";
import { CRUD_PERMISSIONS } from "../constants/user";
import useCheckAuthorization from "../hooks/checkAuthorization";
import ApproveRejectOptions from './common/ApproveRejectOptions';


function GSTR1SheetModal({showSheetModal, handleSheetClose, handleSheetSubmit, handleSheetSubmitManager, sheetSummaryData, viewOnlySheetData, getStatus, hasSubmitAccess, showSkipModal, handleSkipClose, handleSkipSubmit, setShowSkipModal}) {
    const {selectedMonth, selectedYear } = useSelector((state) => state.common);
    const [loading, setLoading] = useState(false);
    //const [fileUrl, setFileUrl] = useState('');
    const [allCells, setAllCells] = useState([]);
    const [refreshAllCells, setRefreshAllCells] = useState(false);
    const [status, setStatus] = useState(4);
    //const [sheetData, setSheetData] = useState(0);
    const [data, setData] = useState([]);
    const [modalInfo, setModalInfo] = useState({
        isVisible: false,
        cell: null,
        note: '',
        noteArr: []
    });
    const { client_id} = useParams();
    const manageAllClientsPermission = useCheckAuthorization(CRUD_PERMISSIONS.sheet.manage_clients);

    const getExcelCellName = ({ row, col }) => {
        const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

        let colName = '';
        while (col >= 0) {
        colName = letters[col % 26] + colName;
        col = Math.floor(col / 26) - 1;
        }

        const rowName = (row + 1).toString();
        return `${colName}${rowName}`;
    };

    function reverseExcelName(cellName) {
        const match = cellName.match(/^([A-Z]+)(\d+)$/);
        if (!match) {
        throw new Error('Invalid cell name');
        }

        const colLetters = match[1];
        const row = parseInt(match[2], 10) - 1; // Subtract 1 for zero-based index

        // Convert column letters to a number, starting from 0
        let col = 0;
        for (let i = 0; i < colLetters.length; i++) {
        col = col * 26 + (colLetters.charCodeAt(i) - 65);
        }
        return { col, row };
    }

    const addComment = async (note) => {
        try {
        const promise = instance.post(`/api/v1/client/add/comment/${client_id}`, {
            year: selectedYear,
            month: selectedMonth,
            comment: note,
            cell_no: getExcelCellName({
            row: modalInfo.cell.row,
            col: modalInfo.cell.col
            }),
            status: status
        });

        const res = await toast.promise(promise, {
            loading: 'Saving...',
            success: <b>Comment saved!</b>,
            error: <b>Could not save.</b>
        });
        const noteArr = [...modalInfo.cell.noteArr, res.data.data];
        updateNote(note, { row:modalInfo.cell.row, col:modalInfo.cell.col }, noteArr);
        } catch (error) {
        console.error('error', error);
        }
    };

    const fetchAndProcessFile = async (fileUrl) => {
        try {
        setLoading(true);
        const response = await fetch(fileUrl);
        const arrayBuffer = await response.arrayBuffer();
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const rows = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

        setData(rows);
        setRefreshAllCells((ref) => !ref);
        } catch (error) {
        console.error('Failed to fetch and process the file', error);
        } finally {
        setLoading(false);
        }
    };

    const handleCellRightClick = (rowIndex, colIndex, e) => {
        e.preventDefault();
        if(!viewOnlySheetData){
            const cellData = data[rowIndex][colIndex];
            const cell = {
            row: rowIndex,
            col: colIndex,
            note: cellData?.note || '',
            noteArr: cellData?.noteArr || []
            };

            setModalInfo({ ...modalInfo, isVisible: true, cell });
        }
    };

    const saveNote = (note, rowColObj, noteArr,) => {
        if (note) {
        addComment(note);
        const updatedData = [...data];
        const { row, col } = rowColObj ? rowColObj : modalInfo.cell;

        if (updatedData[row]) {
            if (!updatedData[row][col]?.note) {
            updatedData[row][col] = { value: updatedData[row][col], note, noteArr };
            } else if (updatedData[row][col]) {
            updatedData[row][col].note = note;
            } else {
            // Handle the case where col doesn't exist in the row
            // updatedData[row][col] = { value: "", note }; // or handle as needed
            }
        } else {
            // Handle the case where the row doesn't exist
            updatedData[row] = [];
            updatedData[row][col] = { value: '', note, noteArr }; // or handle as needed
        }
        setData(updatedData);
        fetchAndProcessFile(sheetSummaryData?.sheet?.url);
        setModalInfo({ ...modalInfo, isVisible: false, cell: null, note: '', noteArr });
        }
    };

    const updateNote = (note, rowColObj, noteArr,) => {
        if (note) {
        //addComment(note);
        //console.log('1 data', data);
        const updatedData = [...data];
        const { row, col } = rowColObj ? rowColObj : modalInfo.cell;

        if (updatedData[row]) {
            if (!updatedData[row][col]?.note) {
            updatedData[row][col] = { value: updatedData[row][col], note, noteArr };
            } else if (updatedData[row][col]) {
            updatedData[row][col].note = note;
            updatedData[row][col].noteArr = noteArr;
            } else {
            // Handle the case where col doesn't exist in the row
            // updatedData[row][col] = { value: "", note }; // or handle as needed
            }
        } else {
            // Handle the case where the row doesn't exist
            updatedData[row] = [];
            updatedData[row][col] = { value: '', note, noteArr }; // or handle as needed
        }
        setData(updatedData);
        setModalInfo({ ...modalInfo, isVisible: false, cell: null, note: '', noteArr });
        }
    };


    const isCellHighlighted = (rowIndex, colIndex) => {
        const cellName = getExcelCellName({ row: rowIndex, col: colIndex });
        return allCells?.some((cell) => cell.cell_no === cellName);
    };

    useEffect(() => {
        if(!viewOnlySheetData){
            const validCells = allCells.filter((cell) => cell.cell_no !== 'None');
            validCells.forEach((cell) => {
            const { row, col } = reverseExcelName(cell.cell_no);
            const note = cell.comments?.[0].comment;
            const noteArr = cell.comments;
            if (note) {
                updateNote(note, { row, col }, noteArr);
            }
            });
        }
    }, [allCells, refreshAllCells, viewOnlySheetData]);

    useEffect(() => {
        if (sheetSummaryData && !viewOnlySheetData){
            setAllCells(sheetSummaryData.all_cell);
            fetchAndProcessFile(sheetSummaryData?.sheet?.url);
        }   
        else if(viewOnlySheetData){
            setAllCells([]);
            fetchAndProcessFile(viewOnlySheetData.url);
        }
    }, [sheetSummaryData, viewOnlySheetData, showSheetModal]);
    
  return (
    <>
      {/* Modal structure */}
      <Modal size="xl" show={showSheetModal} onHide={handleSheetClose} backdrop="static" enforceFocus={false} className="excel-sheet-modal">
        <Modal.Header closeButton>
          <Modal.Title>{!viewOnlySheetData ? sheetSummaryData.sheet?.file_name : viewOnlySheetData.file_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {loading && data ? (
                <p className="text-center my-4">...loading</p>
            ) : (
                <div className="">
                <div className="table-container">
                    <table>
                    <thead>
                        <tr>
                        <th></th>
                        {data[0] &&
                            ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'].map((_, index) => (
                            <th key={index}>{String.fromCharCode(65 + index)}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data[0] && data.map((row, rowIndex) =>
                        rowIndex >= 8 ? (
                            <tr key={rowIndex}>
                            <th>{rowIndex + 1}</th>
                            {row.length === 1 ? (
                                <td className="bg-[#eff2f3] text-[#1e4466]" colSpan={12}>
                                {typeof row[0] === 'object' && row[0] !== null
                                    ? row[0]?.value !== undefined && row[0]?.value !== null
                                        ? row[0]?.value
                                        : ''
                                    : row[0] !== undefined && row[0] !== null
                                    ? row[0]
                                    : ''}
                                </td>
                            ) : (
                                Array.from({ length: 12 }).map((_, colIndex) => (
                                <td
                                    className={`${rowIndex === 8 ? 'bg-[#0d4473] text-white' : ''}`}
                                    id={getExcelCellName({
                                    row: rowIndex,
                                    col: colIndex
                                    })}
                                    key={colIndex}
                                    onContextMenu={(e) => handleCellRightClick(rowIndex, colIndex, e)}
                                    style={{
                                    backgroundColor: isCellHighlighted(rowIndex, colIndex)
                                        ? '#FFFFCC'
                                        : row[colIndex]?.note
                                        ? '#FFFFCC'
                                        : ''
                                    }}>
                                    {typeof row[colIndex] === 'object' && row[colIndex] !== null
                                    ? row[colIndex]?.value !== undefined && row[colIndex]?.value !== null
                                        ? row[colIndex]?.value
                                        : ''
                                    : row[colIndex] !== undefined && row[colIndex] !== null
                                    ? row[colIndex]
                                    : ''}
                                </td>
                                ))
                            )}
                            </tr>
                        ) : (
                            <tr key={rowIndex}>
                            <td>{rowIndex + 1}</td>
                            {row?.length === 1 ? (
                                <td className="bg-[#eff2f3] text-[#1e4466]" colSpan={12}>
                                {typeof row[0] === 'object' && row[0] !== null
                                    ? row[0]?.value !== undefined && row[0]?.value !== null
                                        ? row[0]?.value
                                        : ''
                                    : row[0] !== undefined && row[0] !== null
                                    ? row[0]
                                    : ''}
                                </td>
                            ) : (
                                Array.from({ length: 12 }).map((_, colIndex) => (
                                <td
                                    key={colIndex}
                                    id={getExcelCellName({
                                    row: rowIndex,
                                    col: colIndex
                                    })}
                                    onContextMenu={(e) => handleCellRightClick(rowIndex, colIndex, e)}
                                    style={{
                                    backgroundColor: isCellHighlighted(rowIndex, colIndex)
                                        ? '#FFFFCC'
                                        : row[colIndex] && row[colIndex]?.note
                                        ? '#FFFFCC'
                                        : ''
                                    }}>
                                    {typeof row[colIndex] === 'object' && row[colIndex] !== null
                                    ? row[colIndex]?.value !== undefined && row[colIndex]?.value !== null
                                        ? row[colIndex]?.value
                                        : ''
                                    : row[colIndex] !== undefined && row[colIndex] !== null
                                    ? row[colIndex]
                                    : ''}
                                </td>
                                ))
                            )}
                            </tr>
                        )
                        )}
                    </tbody>
                    </table>
                </div>
                </div>
            )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" onClick={handleSheetClose}>
            Close
          </Button>
          {!viewOnlySheetData && <ApproveRejectOptions manageAllClientsPermission={manageAllClientsPermission} sheetSummaryData={sheetSummaryData} loading={loading} handleSheetSubmit={handleSheetSubmit} handleSheetClose={handleSheetClose} handleSheetSubmitManager={handleSheetSubmitManager}  getStatus={getStatus} hasSubmitAccess={hasSubmitAccess} showSkipModal={showSkipModal} handleSkipClose={handleSkipClose} handleSkipSubmit={handleSkipSubmit} setShowSkipModal={setShowSkipModal}/>}
          
        </Modal.Footer>
      </Modal>
        {modalInfo.isVisible && (
            <NoteModal
            noteArr={modalInfo.cell.noteArr}
            note={modalInfo.cell.note}
            onSave={saveNote}
            status={status}
            setStatus={setStatus}
            onClose={() => setModalInfo({ ...modalInfo, isVisible: false, cell: null, note: '' })}
            />
        )}
    </>
  );
}

export default GSTR1SheetModal;
