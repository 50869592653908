import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import MultiStepProgressBar from "./MultiStepProgressBar";

const DescriptionCell = ({ description,levels, progressbar }) => {
  const [showModal, setShowModal] = useState(false);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const truncateDescription = (text, wordLimit) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = text; 
    const plainText = tempDiv.textContent || tempDiv.innerText || "";
    const words = plainText.split(" ");
    if (words.length > wordLimit) {
      return `${words.slice(0, wordLimit).join(" ")}...`;
    }
    return plainText;
  };
  
  return (
    <td style={{  verticalAlign: "center" }}>
      <div style={{ marginBottom: "8px",textAlign: "left" }}>
      {truncateDescription(description, 15)}
        {description.split(" ").length > 15 &&(
          <>
            <span
              onClick={handleShowModal}
              style={{ color: "blue", cursor: "pointer", marginLeft: "5px" }}
            >
              show more
            </span>
            <Modal
              show={showModal}
              onHide={handleCloseModal}
              centered
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title>Full Description</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>{description}</p>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </div>
      {progressbar && (
        <div
          style={{
            maxWidth: "1100px",
            height: "40px",
          }}
        >
          <MultiStepProgressBar approverLevels={levels} />
        </div>
      )}
    </td>
  );
};

export default DescriptionCell;
