import React, { useEffect, useState } from "react";
import "./Forex.css";
import add from "../../images/save.png";
import remove from "../../images/remove.png";
import { Button } from "react-bootstrap";
import instance from "../../services/baseService";
import CurrencyDropdown from "./CurrencyDropdown";
import Base from "../Base";
const today = new Date();

const Forex = () => {
  const [currencies, setCurrencies] = useState([{ currency: "", amount: "" }]);
  const [date, setDate] = useState(
    today.getFullYear() +
      "-" +
      (today.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      today.getDate().toString().padStart(2, "0")
  );
  const [currencyData, SetCurrencyData] = useState([]);

  useEffect(() => {
    fetchCurrency();
  }, []);

  const fetchCurrency = async () => {
    try {
      const [currencyDataResponse, currenciesResponse] = await Promise.all([
        await instance.post(`/api/v1/forex/list`, {
          page: 1,
          perpage: 25,
        }),
        await instance.post(`/api/v1/forex/added/list`, {
          current_date: date,
        }),
      ]);
      const currencyList = currenciesResponse.data.data.list;
      setCurrencies(
        currencyList.length > 0 ? currencyList : [{ currency: "", amount: "" }]
      );
      SetCurrencyData(currencyDataResponse.data.data.list);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const handleAddCurrency = () => {
    setCurrencies([...currencies, { currency: "", amount: "" }]);
  };

  const handleRemoveCurrency = (index) => {
    const updatedCurrencies = currencies.filter((_, i) => i !== index);
    setCurrencies(updatedCurrencies);
  };

  const handleCurrencyChange = (index, field, value) => {
    const updatedCurrencies = currencies.map((currency, i) =>
      i === index ? { ...currency, [field]: parseInt(value) } : currency
    );
    setCurrencies(updatedCurrencies);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await instance.post(`/api/v1/forex/bulk/create`, {
        date: date,
        forex_data: currencies
      });
      const currencyArray = response.data.data;
      alert("Saved successfully");
      setCurrencies(currencyArray);
      setDate(date);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const dateHandler = async (e) => {
    try {
      setDate(e.target.value);
      const currenciesResponse = await instance.post(
        `/api/v1/forex/added/list`,
        {
          current_date: e.target.value,
        }
      );
      const currencyList = currenciesResponse.data.data.list;

      setCurrencies(
        currencyList.length > 0 ? currencyList : [{ currency: "", amount: "" }]
      );
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  return (
    <Base hideDatepicker={true}>
      <div className="forex-container">
        <div style={{ margin: "2em 1em", textAlign: "center" }}>
          <h2>Add Forex Rates</h2>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="form-group date-pick">
            <label htmlFor="date">Date :</label>
            <div className="date-input-wrapper">
              <input
                type="date"
                id="date"
                name="date"
                value={date}
                onChange={dateHandler}
                className="form-control"
              />
            </div>
          </div>

          <div style={{ margin: "2em 0em", display: "flex" }}>
            <h3>Add Forex Currency</h3>
            <img
              src={add}
              alt="Logo"
              className="add-button"
              onClick={handleAddCurrency}
            />
          </div>

          {currencies.map((currency, index) => (
            <div key={index} className="currency-row">
              <div className="form-group">
                <label
                  htmlFor={`currency-code-${index}`}
                  style={{ fontSize: "1.2em", fontWeight: "bold" }}
                >
                  Currency Code
                </label>
                <CurrencyDropdown
                  index={index}
                  currency={currency}
                  handleCurrencyChange={handleCurrencyChange}
                  currencyData={currencyData}
                />
              </div>
              <div className="form-group">
                <label
                  htmlFor={`currency-amount-${index}`}
                  style={{ fontSize: "1.2em", fontWeight: "bold" }}
                >
                  Amount
                </label>
                <input
                  type="text"
                  id={`currency-amount-${index}`}
                  placeholder="Amount"
                  value={currency.amount}
                  onChange={(e) =>
                    handleCurrencyChange(index, "amount", e.target.value)
                  }
                  className="form-control"
                />
              </div>
              {currencies.length > 1 && (
                <img
                  src={remove}
                  alt="Logo"
                  style={{ width: "2.5em" }}
                  onClick={() => handleRemoveCurrency(index)}
                />
              )}
            </div>
          ))}

          <div className="form-group">
            <Button type="submit" className="save-btn">
              Save
            </Button>
          </div>
        </form>
      </div>
    </Base>
  );
};

export default Forex;
