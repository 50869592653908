import {string, number,object, boolean, array} from 'yup';
import moment from 'moment';

export const ROUTES = {
    LIST_CLIENT: (page_number) => `/api/v1/client/list/${page_number}`,
    LIST_PENDING_CLIENT: (page_number, per_page) => `/api/v1/client/pending/list/${page_number}/${per_page}`,
    CREATE_CLIENT: "/api/v1/client/create",
    GET_SINGLE_CLIENT: (id) => `/api/v1/client/view/${id}`,
    DELETE_CLIENT: (id) => `/api/v1/client/delete/${id}`,
    UPDATE_CLIENT: (id) => `/api/v1/client/update/${id}`,
    UPDATE_PENDING_CLIENT_STATUS: (id) => `/api/v1/client/approve/${id}`
}

export const ACTION_TYPES = {
    LIST_CLIENTS: 'LIST_CLIENTS'
}

export const CREATE_CLIENT_SCHEMA = object().shape({
    client_name: string().required("Full Name is required"),
    gstin: string().required("GST Number is required"),
    phone : number().transform((value) => Number.isNaN(value) ? null : value )
    .nullable().min(10),
    email: string().email().required("Email is required"),
    from_email: string().email().required("From Email is required"),
    city: string(),
    state: string(),
    country: string(),
    pan: string(),
    gst_jurisdiction: string(),
    client_class: string(),
    assignee_id:number().transform((value) => Number.isNaN(value) ? null : value ).nullable(),
    responsible_person_id:number().transform((value) => Number.isNaN(value) ? null : value ).nullable(),
    poc_mail:string(),
    is_active:boolean(),
    approver_levels: array().of(
        number()
            .transform((value) => (Number.isNaN(value) ? null : value))
            .required("Client code must be a number")
    ).min(1, "At least one Approver Level is required"),
})

export const TABLE_HEADERS = [
    {
        id: 'client_name',
        name: 'CLIENT NAME'
    },
    {
        id: 'gstin',
        name: 'GST NUMBER',
    },
    {
        id: 'updated_date',
        name: 'LAST UPDATED',
        modifier: (date) => moment(date).format('YYYY-MM-DD')
    }
]


export const CREATE_CLIENT_FIELDS = {
        client_name: { name: 'client_name', placeholder: 'Full Name', type: 'text', label: 'Full Name'} ,
        gstin: { name: 'gstin', placeholder: 'GST Number', type: 'text', label: 'GST Number'},
        phone : { name: 'phone', placeholder: 'Phone Number', type: 'text', label: 'Phone Number'},
        email: { name: 'email', placeholder: 'Email', type: 'text', label: 'Email'},
        from_email: { name: 'from_email', placeholder: 'From Email', type: 'text', label: 'From Email'},
        city: { name: 'city', placeholder: 'City', type: 'text', label: 'City'},
        state: { name: 'state', placeholder: 'State', type: 'text', label: 'State'},
        country: { name: 'country', placeholder: 'Country', type: 'text', label: 'Country'},
        pan: { name: 'pan', placeholder: 'PAN Number', type: 'text', label: 'PAN Number'},
        gst_jurisdiction: { name: 'gst_jurisdiction', placeholder: 'Client GST Jurisdiction', type: 'text', label: 'Client GST Jurisdiction'},
        client_class: { name: 'client_class', placeholder: 'Client Class', type: 'text', label: 'Client Class'},
        assignee_id: { name: 'assignee_id', placeholder: 'Assignee(Preface)', type: 'select', label: 'Assignee(Preface)'},
        responsible_person_id: { name: 'responsible_person_id', placeholder: 'Responsible Person (Preface)', type: 'select', label: 'Responsible Person (Preface)'},
        poc_mail: { name: 'poc_mail', placeholder: 'POC Mail ID (Preface)', type: 'text', label: 'POC Mail ID (Preface)'},
        approver_levels: {
            name: 'approver_levels', 
            type: 'checkbox_group', 
            label: 'Client Approval Hierarchy', 
            options: [
                { label: 'Director', value: 1 },
                { label: 'Manager', value: 2 },
                { label: 'Senior associate', value: 3 },
                { label: 'Associate', value: 4 }
            ]
        },
        is_active: { name: 'is_active', placeholder: 'Active', type: 'checkbox', label: 'Active'},
}