import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import instance from '../services/baseService';
import { errorMsgCreator } from '../utils';

// Async Thunks

export const createFacilitation = createAsyncThunk(
  'facilitation/createFacilitation',
  async ({ data }, thunkAPI) => {
    try {
      const response = await instance.post("/api/v1/client/facilitation/create", data);
      if (response.data) {
        thunkAPI.dispatch(getFacilitation({})); 
        return response.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

export const updateFacilitation = createAsyncThunk(
  'facilitation/updateFacilitation',
  async ({ data }, thunkAPI) => {
    try {
      const response = await instance.post("/api/v1/client/facilitation/update", data);
      if (response.data) {
        thunkAPI.dispatch(getFacilitation({})); 
        return response.data;
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

export const getFacilitation = createAsyncThunk(
  'facilitation/getFacilitation',
  async ({ page = 1, perpage = 25 }, thunkAPI) => {
    try {
      const response = await instance.post("api/v1/client/facilitation/list", {
        page,
        perpage, 
      });
      return response.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);

export const deleteFacilitation = createAsyncThunk(
  'facilitation/deleteFacilitation',
  async ({  data }, thunkAPI) => {
    try {
      const response = await instance.post("/api/v1/client/facilitation/delete", data);
      if (response.data) {
        thunkAPI.dispatch(getFacilitation({})); 
      }
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data);
    }
  }
);



export const facilitationSlice = createSlice({
  name: 'facilitation',
  initialState: {
    facilitationData: [],
    loading: false,
    error: null,
    success: false,
    successMsg: null,
    total_count:0
  },
  reducers: {
    resetFacilitationState(state) {
      state.error = null;
      state.success = false;
      state.successMsg = null;
      state.total_count = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      // Create Facilitation
      .addCase(createFacilitation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createFacilitation.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.successMsg = 'Successfully created';
        state.facilitationData = action.payload;
      })
      .addCase(createFacilitation.rejected, (state, action) => {
        state.loading = false;
        state.error = errorMsgCreator('Failed to create facilitation', action.payload);
      })

      // Update Facilitation
      .addCase(updateFacilitation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateFacilitation.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.successMsg = 'Successfully updated';
        state.facilitationData = action.payload;
      })
      .addCase(updateFacilitation.rejected, (state, action) => {
        state.loading = false;
        state.error = errorMsgCreator('Failed to update facilitation', action.payload);
      })

      // Get Facilitation
      .addCase(getFacilitation.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
        
      })
      .addCase(getFacilitation.fulfilled, (state, action) => {
        state.facilitationData = action.payload.data?.list ;
        state.loading = false;
        state.success = true;
        state.total_count = action.payload.data.total_count;

      })
      .addCase(getFacilitation.rejected, (state, action) => {
        state.loading = false;
        state.error = errorMsgCreator(action.payload);
      })

      // Delete Facilitation
      .addCase(deleteFacilitation.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteFacilitation.fulfilled, (state, action) => {
        state.loading = false;
        state.successMsg = 'Successfully deleted';
        state.success = true;
      })
      .addCase(deleteFacilitation.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

export const { resetFacilitationState } = facilitationSlice.actions;
export default facilitationSlice.reducer;