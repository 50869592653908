import moment  from "moment"
export const ROUTES = {
    UPLOAD_FILE: (folder_id) => `api/v1/statement/folder/upload/${folder_id}`,
    GET_FILES: (folder_id) => `/api/v1/statement/folder/file/list/${folder_id}`,
    CREATE_CLIENT: "/api/v1/client/create",
    DELETE_FILE:    (id, folder_id) => `/api/v1/statement/folder/file/delete/${folder_id}/${id}`,
    GET_SINGLE_CLIENT: (id) => `/api/v1/client/view/${id}`,
    EGM_UPDATE: (id, folder_id) => `/api/v1/statement/folder/file/egm/${folder_id}/${id}`,
    DOWNLOAD_EXCEL: (folder_id, is_refund) => `/api/v1/statement/folder/download/${folder_id}${is_refund && is_refund === 1 ? `/${is_refund}` : ''}`,
    DOWNLOAD_ERROR_LOGS: (folder_id, is_refund) => `/api/v1/statement/folder/download/error/${folder_id}${is_refund && is_refund === 1 ? `/${is_refund}` : ''}`,
    DOWNLOAD_HSN_LOGS: (folder_id, is_refund) => `/api/v1/statement/folder/download/hsn/${folder_id}${is_refund && is_refund === 1 ? `/${is_refund}` : ''}`
}

export const  successStatusIcons = [1,4,7]

export const errorStatus = {
    2: 'Duplicate - File with same invoice number exist already',
    3: 'Failed - File couldn\'t be processed',
    5: 'Wrong Period - Invoice date does not belong to this period',
    6: 'GST Number - GST number does not match with client'
}

export const TABLE_HEADERS = [
    {
        id: 'file_name',
        name: 'FILE NAME'
    },
    {
        id: 'file_size',
        name: 'FILE SIZE',
        suffix: "KB",
        modifier: (data) => data*0.001
    },
    {
        id: 'updated_date',
        name: 'LAST UPDATED',
        modifier: (date) => moment(date).format('YYYY-MM-DD')
    }
]

export const EGM_TABLE_HEADERS = [
    {
        id: 'invoice_number',
        name: 'Invoice Number',
        key: 'invoice_number_'
    },
    {
        id: 'invoice_date',
        name: 'Invoice Date',
        key: 'invoice_date_'
    },
    {
        id: 'consignee_name',
        name: 'Consignee Name',
        key: 'consignee_name_'
        
    },
    {
        id: 'port_code',
        name: 'Port Code',
        key: 'port_code_'
    },
    {
        id: 'shipping_number',
        name: 'Shipping Number',
        key: 'shipping_number_'
    },
    {
        id: 'shipping_date',
        name: 'Shipping Date',
        key: 'shipping_date_'
    },
    {
        id: 'egm_number',
        name: 'EGM Number',
        field: true,
        key: 'egm_number_'
    },
    {
        id: 'egm_date',
        name: 'EGM Date',
        field: true,
        key: 'egm_date_'
    } 
]