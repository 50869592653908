import {string,object} from 'yup';


// Fields for Facilitation Creation
export const FACILITATION_CREATION_FIELDS = {
  
  name: {
      type: "text",
      name: "name",
      label: "Name"
    },
    
   
  };