import Base from './Base';
import React, { useEffect, useState } from 'react';
import { Form, Button, Card, Row, Col } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { updateProfile, clearMessages, getUserDetails } from '../slices/commonReducer';

const ManageProfile = () => {
    //const { userData, successMsg, errorMsg, profile } = useSelector(state => state.user)
    const { name, email, role, mobile, errorMsg, successMsg } = useSelector((state) => state.common);
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        name: name,
        mobile: mobile,
        email: email
    });

    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    useEffect(() => {
        if (!!errorMsg) {
            toast.error(errorMsg, {
                position: 'top-center'
            })
        }
        if (!!successMsg) {
            toast.success(successMsg, {
                position: 'top-center'
            })
        }
        dispatch(clearMessages());
        dispatch(getUserDetails())
    }, [successMsg, errorMsg])

    const handleSubmit = (e) => {
        e.preventDefault();
        setError('');
        const { name, mobile } = formData;
        console.log(formData);
        dispatch(updateProfile({ name, mobile }))
    };

    return (
        <Base>
            <Row>
                <Col xl={12} lg={12}>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Edit Personal Information</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <div className="new-user-info">
                                <Form onSubmit={handleSubmit}>
                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="name">
                                                <Form.Label>Name:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Name"
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group controlId="mobile">
                                                <Form.Label>Mobile Number:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Mobile Number"
                                                    value={formData.mobile}
                                                    onChange={handleChange}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col md={6}>
                                            <Form.Group controlId="email">
                                                <Form.Label>Email:</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Email"
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Button type="submit" className="btn btn-primary mt-3">
                                        Edit Profile
                                    </Button>
                                </Form>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Base>
    );
};

export default ManageProfile;
