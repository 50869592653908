import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

function AddFacilitation(props) {
  const {
    handleSubmit,
    fields,
    errors,
    show,
    onHideFunc,
    values,
    isUpdate,
    id,
  } = props;

  const [data, setData] = useState({});
  console.log("values",values)
  const handleChange = (e, field) => {
    const fieldData = { ...data };
    fieldData[field] = e.target.value;
    setData(fieldData);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data Submitted:", e);
    if (handleSubmit) {
      handleSubmit(e, isUpdate, id);
    }
  };

  return (
    <Modal
      aria-labelledby="contained-modal-title-vcenter"
      show={show}
      onHide={onHideFunc}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {!isUpdate ? "Create New Facilitation" : "Update Facilitation"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <div style={{ minHeight: "100px" }}>
          <div className="sign-user_card">
            <form className="login-content" onSubmit={handleFormSubmit}>
              <div className="row">
                {!!fields &&
                  Object.keys(fields).map((field) => (
                    <div className="col-lg-12" key={field}>
                      <div className="floating-label form-group">
                        {fields[field]["type"] === "text" && (
                          <input
                            className="floating-input form-control"
                            name={fields[field]["name"]}
                            type={fields[field]["type"]}
                            placeholder={fields[field]["label"]}
                            value={data[field] || values[field] || ""}
                            onChange={(e) => handleChange(e, field)}
                          />
                        )}
                        {errors[field] && (
                          <span className="error text-danger">
                            {errors[field]}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
              <button type="submit" className="btn btn-primary">
                {!isUpdate ? "Create" : "Update"} &raquo;
              </button>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddFacilitation;
