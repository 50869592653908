import {combineReducers} from "redux";
import clientReducer from "./clientReducer";
import periodReducer from "./periodReducer"
import fileUploadReducer from "./fileUploadReducer";
import commonReducer from "./commonReducer";
import userReducer from "./userReducer";
import notificationReducer from "./notificationReducer";

const rootReducer = combineReducers({ 
        clients: clientReducer,
        periods: periodReducer,
        files: fileUploadReducer, 
        common: commonReducer,
        user: userReducer,
        notification: notificationReducer
    });

export default rootReducer