import React from 'react'
import { Badge, Button, Col, Row } from 'react-bootstrap';
import { RESTRICT_ASSIGN_ROLES } from '../../constants/user';
import { useSelector } from 'react-redux';
import SheetSubmitSkipModal from './SheetSubmitSkipModal';

const ApproveRejectOptions = ({manageAllClientsPermission, sheetSummaryData, loading, handleSheetSubmit, handleSheetClose, handleSheetSubmitManager, getStatus, hasSubmitAccess, showSkipModal, handleSkipClose, handleSkipSubmit, setShowSkipModal}) => {
    const { role_id: roleId} = useSelector(state => state.common);

    // Check if user role is higher or equal to the top level approver
    const isTopLevelApprover = Object.entries(sheetSummaryData?.approver_levels).every(
        ([key, role]) => parseInt(key) >= roleId
    );
  return (
    <>


    {/* Approval Status */}
    {sheetSummaryData.sheet.status === 1 && sheetSummaryData.sheet.client_approval === 1 && sheetSummaryData.sheet.preface_approval === 1 && 
        <h5 className=""><Badge bg="primary">Approved by Client</Badge></h5>
    }
    {sheetSummaryData.sheet.status === 2 && sheetSummaryData.sheet.client_approval === 1 && 
        <>
        <h5 className=""><Badge bg="secondary">Rejected</Badge></h5>
        </>
    }
    {!hasSubmitAccess && ((sheetSummaryData.sheet.status === 3 && sheetSummaryData.sheet.preface_approval === 1) || ((sheetSummaryData.sheet.status === 2 || sheetSummaryData.sheet.status === 1) && sheetSummaryData.sheet.client_approval === 0 )) ? 
       <h5 className=""><Badge bg="primary">Waiting For Client Approval</Badge></h5>: <h5 className=""><Badge bg="primary">{getStatus() ? getStatus() : ''}</Badge></h5>
    }
    {hasSubmitAccess && ((sheetSummaryData.sheet.status === 0 && (sheetSummaryData.sheet.preface_approval === 0 || sheetSummaryData.sheet.preface_approval === 1)) || (sheetSummaryData.sheet.status === 2 && sheetSummaryData.sheet.client_approval === 1)  ) && 
        <>
        <Row>
        <Col>
        <Button type="submit" variant="primary" size="sm" disabled ={loading} onClick={() => {handleSheetSubmit(sheetSummaryData.sheet.id, 4); handleSheetClose()}}>
            {isTopLevelApprover ? 'Submit for Client Approval' : 'Submit to next level'}
        </Button>
       
            {!RESTRICT_ASSIGN_ROLES.includes(roleId) &&
            <>
            <span className="mx-1">|</span>
            <Button type="button" variant="outline-primary" size="sm" onClick={() => setShowSkipModal(true)} disabled ={loading}>Skip to next level</Button>
            </>
            }
        </Col>
        </Row>  
        
        </>
    }
    {/* Manager */}
    {hasSubmitAccess && sheetSummaryData.sheet.preface_approval === 0 && sheetSummaryData.sheet.status === 3 && 
    (
    <>
    {
        getStatus() && <>
        <h5 className=""><Badge bg="primary">{getStatus()}</Badge></h5>
        <span className="mx-1">|</span>
        </>
    }
    
    <>
    <Row>
    <Col>
    <Button type="submit" variant="primary" size="sm" onClick={() => {handleSheetSubmitManager(sheetSummaryData.sheet.id, 1); handleSheetClose()}} disabled ={loading} >
    Confirm
    </Button>
    </Col>
    </Row>
    <span className="mx-1">|</span>
    <Row>
    <Col>
    <Button type="submit" variant="outline-primary" size="sm" onClick={() => {handleSheetSubmitManager(sheetSummaryData.sheet.id, 2); handleSheetClose()}} disabled ={loading} >
    Deny
    </Button>
    </Col>
    </Row>
    </>
    </>
    )}

    <SheetSubmitSkipModal showSkipModal={showSkipModal} handleSkipClose={handleSkipClose} handleSkipSubmit={handleSkipSubmit}/>
    </>
  )
}

export default ApproveRejectOptions
