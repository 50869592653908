export const getFileIconClass = (fileName) => {
    if (!fileName) return 'las la-file pa-text-muted'; // Default icon if no file name

    // Extract the file extension
    const fileExtension = fileName.split('.').pop().toLowerCase();

    // Define icon classes based on file extension
    const iconClasses = {
        xlsx: 'las la-file-excel pa-text-success',
        xls: 'las la-file-excel pa-text-success',
        xlsm: 'las la-file-excel pa-text-success',
        xlsb: 'las la-file-excel pa-text-success',
        csv: 'las la-file-csv pa-text-primary',
        pdf: 'las la-file-pdf pa-text-danger',
    };

    // Return the corresponding icon class or default to PDF icon
    return iconClasses[fileExtension] || 'las la-file-pdf pa-text-danger';
};