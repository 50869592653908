// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { FIREBASE_CONFIG,VAPID_KEY  } from "../constants/common";


// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG);
export const messaging = getMessaging(app);

export const generateToken = async () => {
  try{
    const permission = await Notification.requestPermission();
    //granted denied
    if(permission === 'granted'){
      const token = await getToken(messaging, {
        vapidKey: VAPID_KEY
      });
      return token;
    }else {
      // Notify the user to enable notifications again
      alert('Please enable notifications in your browser settings to receive alerts.');
      return null; // Return null if permission is denied
    } 
  }catch (error) {
    console.error('Generate push token error', error);
    //throw error;
  }
}