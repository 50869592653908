import logo from '../logo.png'
import { useState, useEffect } from 'react';
import { LOGIN_SCHEMA } from '../constants/login';
import {signIn, googleLogin} from '../services/baseService'
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import GoogleButton from 'react-google-button'

export default function Login() {

    const [ errors, setErrors] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token')
        if(token) {
            navigate('/');
        }
        
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const values = {};
        values['username'] = e.target['username'].value;
        values['password'] = e.target['password'].value;
        if (localStorage.getItem('preface_admin_fcm_token') !== null) 
            values['fcm_token'] = localStorage.getItem('preface_admin_fcm_token');
        try {
            const data = await LOGIN_SCHEMA.validate(values, { abortEarly: false });
            if (data) {
               signIn(data).then(response => {
                if (response.status)  {
                    navigate('/')
                   }else{
                     setErrors({main: response.message})
                   }
               })
            }   
         } catch (err) {
           const errData =[];
           err.inner.forEach(e => {
               errData[e.path] = e.message
               setErrors(errData);
           });
         }

    }

    const handleGoogleLogin  = useGoogleLogin({
      onSuccess: tokenResponse => {
         console.log(tokenResponse)
         googleLogin({access_token: tokenResponse.access_token}).then(response => {
            if (response.status)  {
                navigate('/')
            }
            else {
               setErrors({main: response.message})
            }
           })
      }
    });


    return (
    <section class="login-content">
    <div class="container h-100">
       <div class="row justify-content-center align-items-center height-self-center">
          <div class="col-md-5 col-sm-12 col-12 align-self-center">
             <div class="sign-user_card">
                   <img src={logo} class="img-fluid rounded-normal light-logo logo" alt="logo" />
                <h3 class="mb-3">Sign In</h3>
                <p>Login to stay connected.</p>
                {errors['main'] && <span style={{color: 'red'}}>{errors['main']}</span>}
                <form onSubmit={handleSubmit}>
                   <div class="row">
                      <div class="col-lg-12">
                         <div class="floating-label form-group">
                            <input class="floating-input form-control" name="username" type="username" placeholder=" " />
                            <label>Username</label>
                            {errors["username"] && (<span class="error text-danger">{errors["username"]}</span>)}
                         </div>
                      </div>
                      <div class="col-lg-12">
                         <div class="floating-label form-group">
                            <input class="floating-input form-control" name="password" type="password" placeholder=" " />
                            <label>Password</label>
                            {errors["password"] && (<span class="error text-danger">{errors["password"]}</span>)}
                         </div>
                      </div>
                      <div class="col-lg-6">
                         <div class="custom-control custom-checkbox mb-3 text-left">
                            <input type="checkbox" class="custom-control-input" id="customCheck1"/>
                            <label class="custom-control-label" htmlFor="customCheck1">Remember Me</label>
                         </div>
                      </div>
                      <div class="col-lg-6">
                         <a href="auth-recoverpw.html" class="text-primary float-right">Forgot Password?</a>
                      </div>
                   </div>
                   <button type="submit" class="btn btn-primary">Sign In</button>
                </form>
                <div class="row mt-2 d-flex justify-content-center">
                  <GoogleButton onClick={() => handleGoogleLogin()} />
                </div>
                
             </div>
          </div>
       </div>
    </div>
 </section>)
}