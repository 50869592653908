import React from 'react';
import { Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaCheck, FaClock, FaFastForward  } from 'react-icons/fa';
import { useSelector } from 'react-redux';

const StepProgressBar = ({ approverLevels  }) => {
    const { role_id: roleId} = useSelector(state => state.common);
    const sortedRoles = Object.entries(approverLevels).sort(([a], [b]) => b - a);

    const getStatus = (roleKey) => {
        const roleProgress = approverLevels[roleKey].progress;
        if (roleProgress === 1) return "approved"; 
        if (roleProgress === 3) return "skipped"; 
        if (approverLevels["1"]?.progress === 1 || approverLevels["1"]?.progress === 3) {
            return roleKey !== "1" ? "skipped" : "approved";
        } 
        else if (approverLevels["2"]?.progress === 1 || approverLevels["2"]?.progress === 3) {
            return roleKey === "3" || roleKey === "4" ? "skipped" : roleKey === "1" ? "waiting" : "approved";
        } 
        else if (approverLevels["3"]?.progress === 1 || approverLevels["3"]?.progress === 3) {
            return roleKey === "4" ? "skipped" : roleKey === "1" || roleKey === "2" ? "waiting" : "approved";
        } 
        else {
            return "waiting";
        }
    };
    return (
        <div className="container my-5 steps-progressbar">
            <Row className="justify-content-between text-center align-items-center">
            {sortedRoles.map(([dynamicIndex, role], index) => {
                const status = getStatus(dynamicIndex); // Get the status based on the dynamic logic
                const totalRoles = sortedRoles.length;
                return (
                <Col key={dynamicIndex} className="position-relative">
                    <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id={`button-tooltip-${dynamicIndex}`}>
                        {status === 'approved' && "Completed"}
                        {status === 'skipped' && "Skipped pass to higher level"}
                        {status === 'waiting' && "Waiting for submit"}
                        </Tooltip>
                    }
                    container={document.body}
                    >
                    <div
                        className={`rounded-circle p-2 ${
                        status === 'approved'
                            ? 'bg-primary text-white'
                            : status === 'skipped'
                            ? 'bg-primary text-white'
                            : 'border border-primary text-primary'
                        }`}
                        style={{
                        width: '30px',
                        height: '30px',
                        lineHeight: '40px',
                        margin: 'auto',
                        }}
                    >
                        {status === 'approved' && <FaCheck className="text-white" />}
                        {status === 'skipped' && <FaFastForward className="text-white" />}
                        {status === 'waiting' && <FaClock className="text-primary" />}
                    </div>
                    </OverlayTrigger>
                    {index !== 0 && (
                        <div
                        className={`progress-line ${
                           status === 'waiting' ? 'bg-light' : 'bg-primary'
                        }`}
                        style={{
                            width: `calc(100% - 4.5%)`, // Width is 3% less than the parent Col
                            right: `${(52)}%`, // Adjust right position based on index
                            position: 'absolute',
                            top: '24%', // Adjust vertical alignment as needed
                          }}
                        ></div>
                    )}
                    <div className="mt-3">{role.name}</div>
                </Col>
                );
            })}
            </Row>
        </div>
    );
};

export default StepProgressBar;
