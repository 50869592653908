import { createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { ROUTES } from '../constants/common'
import instance from '../services/baseService'
import dayjs from "dayjs"
import { CURRENT_MONTH_ENABLE_DAY } from '../constants/common'


export const getUserDetails = createAsyncThunk('common/getPermissions', async () => {
  const response = await instance.get(ROUTES.PROFILE)
  return response.data
})

export const getSearchResults = createAsyncThunk('common/getSearchResults', async (data, thunkAPI) => {
  console.log(data)
  try {
    const response = await instance.post(ROUTES.SEARCH, data)
    return response.data
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data)
  }

})

export const updateProfile = createAsyncThunk(
  'common/updateProfile',
  async ({ name, mobile }, thunkAPI) => {
      try{
          const response = await instance.post(ROUTES.UPDATE_PROFILE, { name, phone:mobile })
          return response.data
      } catch (error) {
          // Log the error for debugging

          // Return the error message or a custom error
          return thunkAPI.rejectWithValue(error.response ? error.response.data : 'Failed to update profile');
      }
  },
)

export const updatePassword = createAsyncThunk(
  'user/updatePassword',
  async ({  oldPassword, newPassword, repeatPassword }, thunkAPI) => {
      try{
          const response = await instance.post(ROUTES.UPDATE_PASSWORD, { old_password: oldPassword, new_password:newPassword, confirm_new_password:repeatPassword })
          return response.data
      } catch (error) {
          // Log the error for debugging

          // Return the error message or a custom error
          return thunkAPI.rejectWithValue(error.response ? error.response.data : 'Failed to update password');
      }
  },
)

const today = dayjs();
export const commonSlice = createSlice({
    name: 'common',
    initialState: {
      loading: false,
      gridView: 'grid',
      screen: 'dashboard',
      permissions: [],
      name: '',
      role: 'Staff',
      email: '',
      mobile: null,
      id: '',
      searchList: [],
      enableSearch: false,
      selectedMonth: today.date() > CURRENT_MONTH_ENABLE_DAY ? today.month() + 1 : today.month(),
      selectedYear: today.year(),
      successMsg: '',
      errorMsg: '',
      role_id: null
    },
    reducers: {
        setScreenView(state, action) {
            state.gridView = action.payload
        },
        setScreen(state, action) {
          state.screen = action.payload
        },
        setLoginInformation(state, action) {
          const { name, email, permissions, role, id, mobile, role_id } = action.payload
          state.name = name
          state.email = email
          state.permissions = permissions
          state.role = role
          state.id = id
          state.mobile = mobile
          state.role_id =role_id
        },
        resetSearch(state) {
          state.searchList = []
        },
        setSearch(state, action) {
          state.enableSearch = action.payload
        },
        setSelectedMonthYear: (state, action) => {
          state.selectedMonth = action.payload.month
          state.selectedYear = action.payload.year
        },
        clearMessages: (state) => {
          state.successMsg = ''
          state.errorMsg = ''
        },
    },
    extraReducers: (builder) => {
      builder.addCase(getUserDetails.pending, (state, action) => {
        state.loading = true
    })
    builder.addCase(getUserDetails.fulfilled, (state, action) => {
      const {name, permission_code, email, phone, role,id} = action?.payload?.data
          state.name = name
          state.email = email
          state.mobile=  phone
          state.permissions = permission_code
          state.role = role?.name
          state.role_id = role?.id
          state.id = id
    })
    builder.addCase(getUserDetails.rejected, (state, action) => {
        state.loading = false
        state.error = 'Unable to fetch user details'
    })
    builder.addCase(getSearchResults.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getSearchResults.fulfilled, (state, action) => {
        state.searchList = action.payload.data.lists
        state.loading = false
    })
    builder.addCase(getSearchResults.rejected, (state, action) => {
        state.loading = false
        state.error = 'Unable to find details'
    })
    builder.addCase(updateProfile.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.successMsg = 'Successfully updated profile'
      state.loading = false
    })
    builder.addCase(updateProfile.rejected, (state, action) => {
        state.errorMsg = 'Failed to update profile'
        state.loading = false
    })
    builder.addCase(updatePassword.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.successMsg = 'Successfully updated password'
      state.loading = false
    })
    builder.addCase(updatePassword.rejected, (state, action) => {
        state.errorMsg = action?.payload?.message || 'Failed to update password';
        state.loading = false
    })
    },
  })

  export const { 
    setScreenView, 
    setScreen,
    setLoginInformation,
    openSearchModal,
    resetSearch,
    setSearch,
    setSelectedMonthYear,
    clearMessages,
    resetDates
   } = commonSlice.actions;

  export default commonSlice.reducer